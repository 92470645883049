import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Button from "../Button";
import { Link, useNavigate } from 'react-router-dom';
import { resetFilters } from "../../redux/reducers/filterReducer";
import { resetSearch } from "../../redux/reducers/searchReducer";
Jobs.propTypes = {};

function Jobs(props) {

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.loading);
  const navigate = useNavigate();
  const data = useSelector((state) => state.jobs.featuredJobs);
  const { className } = props;
  const [dataBlock] = useState({
    title: "Featured Jobs",
    text: "Find the job that’s perfect for you. about 800+ new jobs everyday",
  });

  // function to fetch random jobs 

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      dispatch(resetFilters())
      dispatch(resetSearch())
      // Dispatch the fetchJobs action with the query string
      // await dispatch(RandomJobsAction());
      navigate(`/job-list-sidebar`);
      console.log("Random Jobs fetched successfully");
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  };
  const renderContractTypeLink = (type) => {
    switch (type) {
      case '1':
      case 'Full-time':
        return <Link to="#">Full Time</Link>;
      case '2':
      case 'Part-time':
        return <Link to="#">Part Time</Link>;
      case '3':
        return <Link to="#">Shift Work</Link>;
      case '4':
        return <Link to="#">Intermittent</Link>;
      case '5':
        return <Link to="#">Job Sharing</Link>;
      case '6':
        return <Link to="#">Multiple Schedules</Link>;
      case 'permanent':
        return <Link to="#">Permanent </Link>;
      case 'contract':
        return <Link to="#">Contract</Link>;
      default:
        return null; // Or handle unknown types if needed
    }
  };

  return (
    <section className={className}>
      <div className="container">
        <div className="tf-title style-2">
          <div className="group-title ">
            <h1>{dataBlock.title}</h1>
            <p>{dataBlock.text}</p>
          </div>
        </div>
        <div className="row wow fadeInUp">
          {data.length ? data?.map((idx) => (
            <div key={idx.jobID} className="col-lg-6 ">
              <div className="features-job">
                <div className="job-archive-header">
                  <div className="inner-box">
                    <div className="logo-company">
                      <img src={idx.img} alt="jobtex" />
                    </div>
                    <div className="box-content">
                      <h4>
                        <Link to={`/jobsingle_v1/${idx.jobID}`}>{idx.keyword}</Link>
                      </h4>
                      <h3>
                        <Link to={`/jobsingle_v1/${idx.jobID}`}> {idx.title} </Link>
                        <span className="icon-bolt"></span>
                      </h3>
                      <ul>
                        <li>
                          <span className="icon-map-pin"></span>
                          {idx.location}
                        </li>
                        <li>
                          <span className="icon-calendar"></span>
                          {new Date(idx.exactPostedDate).toDateString()}
                        </li>
                      </ul>
                      <span className="icon-heart"></span>
                    </div>
                  </div>
                </div>
                <div className="job-archive-footer">
                  <div className="job-footer-left">
                    <ul className="job-tag">
                      <li>
                        <Link to="#">{idx.jobType}</Link>
                      </li>
                      <li>
                        <Link to="#">{idx.company}</Link>
                      </li>
                    </ul>
                    <div className="star">
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                      <span className="icon-star-full"></span>
                    </div>
                  </div>
                  <div className="job-footer-right">
                    <div className="price">
                      <span className="icon-dolar1"></span>
                      <p className={
                        (!idx.salary || (typeof idx.salary === 'string'))
                          ? "blurred-salary"
                          : ""
                      }>
                        {(!idx.salary || (typeof idx.salary === 'string'))
                          ? "Blurred Salary"
                          : `${idx.salary} /year`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )) : <p style={{ textAlign: 'center', margin: '20px 0', fontSize: '25px' }}>No featured job found</p>}

          <div className="col-md-12">
            <div className="wrap-button" onClick={handleClick}>

              <Button title={loading ? 'Loading...' : 'See more Jobs'} />

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Jobs;
