import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import dataCate from "../assets/fakeData/dataCategory";
import { jobCategoryOptions, jobTypeOptions, postedTimeOptions } from "../assets/database/options";
import { CustomDropdown } from "./dropdown/CustomDropdown";
import { usCities } from "../assets/database/us-cities";
import { setFilters } from "../redux/reducers/filterReducer";

Category.propTypes = {};

function Category(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { className, changeFilter } = props;

  const [dataBlock] = useState({
    title: "Browse by category",
    text: "Recruitment Made Easy in 100 seconds",
  });

  // Handle category click, include filters
  const handleCategoryClick = async ({ name, value }) => {
    try {
      dispatch(setFilters({ name, value }))
      navigate(`/job-list-sidebar`);
    } catch (error) {
      console.error("Error fetching category jobs:", error);
    }
  };

  return (
    <section className={className}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title">
              <div className="group-title">
                <h1>{dataBlock.title}</h1>
                <p>{dataBlock.text}</p>
              </div>
            </div>
          </div>

          {/* Filters Section */}
          <div className="row mb-4">
            <div className="col-md-3">
              {/* Job Type Filter */}
              <div className="filter-group">
                <label htmlFor="job-type">Job Types:</label>
                {/* <select
                  id="job-type"
                  value={jobType}
                  onChange={handleJobTypeChange}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value="Full-time">Full-time</option>
                  <option value="Part-time">Part-time</option>
                  <option value="Contract">Contract</option>
                </select> */}
                <CustomDropdown
                  isClearable={true}
                  isSearchable={false}
                  options={jobTypeOptions}
                  onChange={option =>
                    option ? changeFilter({ name: 'jobType', value: option.value }) : changeFilter({ name: 'jobType', value: '' })}
                />
              </div>
            </div>

            {/* Salary Range Filter */}
            <div className="col-md-3">
              <div className="filter-group">
                <label htmlFor="salary-range">Category:</label>
                {/* <select
                  id="salary-range"
                  value={salaryRange}
                  onChange={handleSalaryRangeChange}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value="0-50000">0 - 50,000</option>
                  <option value="50000-100000">50,000 - 100,000</option>
                  <option value="100000-150000">100,000 - 150,000</option>
                  <option value="150000+">150,000+</option>
                </select> */}
                <CustomDropdown
                  isClearable={true}
                  isSearchable={false}
                  options={jobCategoryOptions}
                  onChange={option =>
                    option ? changeFilter({ name: 'keyword', value: option.value }) : changeFilter({ name: 'keyword', value: '' })}
                />
              </div>
            </div>

            {/* Salary Range Filter */}
            {/* <div className="col-md-3">
              <div className="filter-group">
                <label htmlFor="salary-range">Salary Range:</label>
                <select
                  id="salary-range"
                  value={salaryRange}
                  onChange={handleSalaryRangeChange}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value="0-50000">0 - 50,000</option>
                  <option value="50000-100000">50,000 - 100,000</option>
                  <option value="100000-150000">100,000 - 150,000</option>
                  <option value="150000+">150,000+</option>
                </select>
              </div>
            </div> */}

            <div className="col-md-3">
              {/* Location Filter */}
              <div className="filter-group">
                <label htmlFor="location">Location:</label>
                {/* <select
                  id="location"
                  value={location}
                  onChange={handleLocationChange}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value="Remote">Remote</option>
                  <option value="On-site">On-site</option>
                  <option value="Hybrid">Hybrid</option>
                </select> */}

                <CustomDropdown
                  isClearable={true}
                  options={usCities}
                  onChange={option =>
                    option ? changeFilter({ name: 'location', value: option.value }) : changeFilter({ name: 'location', value: '' })}
                />
              </div>
            </div>

            {/* Experience Level Filter */}
            {/* <div className="col-md-3">
              <div className="filter-group">
                <label htmlFor="experience-level">Experience Level:</label>
                <select
                  id="experience-level"
                  value={experienceLevel}
                  onChange={handleExperienceLevelChange}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value="Entry-level">Entry-level</option>
                  <option value="Mid-level">Mid-level</option>
                  <option value="Senior-level">Senior-level</option>
                </select>
              </div>
            </div> */}

            <div className="col-md-3">
              {/* Experience Level Filter */}
              <div className="filter-group">
                <label htmlFor="experience-level">Period
                  :</label>
                <CustomDropdown
                  isClearable={true}
                  isSearchable={false}
                  options={postedTimeOptions}
                  onChange={option =>
                    option ? changeFilter({ name: 'postedTime', value: option.value }) : changeFilter({ name: 'postedTime', value: '' })}
                />
              </div>
            </div>
          </div>

          {/* Category Section */}
          <div className="col-md-12">
            <div
              className="group-category-job wow fadeInUp row"
              data-wow-delay=".2s"
            >
              {dataCate.map((idx) => (
                <div
                  key={idx.id}
                  className={`job-category-box col-4 ${idx.margin}`}
                  onClick={() => { console.log('click first'); handleCategoryClick({ name: 'keyword', value: idx.value }) }}
                >
                  <div className="job-category-header">
                    <h1>
                      <span >
                        {idx.title}
                      </span>
                    </h1>
                  </div>
                  <span
                    className="btn-category-job"
                  >
                    Explore Jobs{" "}
                    <span className="icon-keyboard_arrow_right"></span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category;
