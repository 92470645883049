import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../logonew.png";
import { Link } from "react-router-dom";
import { setSearch } from "../../redux/reducers/searchReducer";

function FormContent() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const search = useSelector((state) => state.search);

  const navigate = useNavigate();

  // Handle form field changes
  const handleChange = (e) => {
    dispatch(setSearch(e.target.value))
  };

  // Submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate('/job-list-sidebar')

  };

  return (
    <div className="content">
      {/* Logo Section */}
      <div id="logo" className="logo m-0 p-0">
        <Link to="/">
          <img
            className="site-logo p-3"
            id="trans-logo"
            src={logo}
            alt="img"
            style={{ width: "300px", height: "auto" }} // Adjust the width as needed
          />
        </Link>
      </div>

      {/* Heading Section */}
      <div className="heading">
        <h2 className="text-dark">Find the job that fits your life</h2>
        <p className="text-dark">
          Your Next Career Move is Just a Click Away – Empowering Product,
          Program, and Project Managers to Explore, Apply, and Succeed!
        </p>
      </div>

      {/* Form Section */}
      <div className="form-sl">
        <form onSubmit={handleSubmit}>
          <div
            className="row-group-search home1"
            style={{ backgroundColor: "#123841" }}
          >
            <div className="form-group-1">
              <input
                type="text"
                className="input-filter-search"
                placeholder="Job title or company"
                name="keyword"
              />
            </div>
            <div className="form-group-2">
              <span className="icon-map-pin"></span>
              {/* Include the SelectLocation component and pass the callback */}
              {/* <SelectLocation onLocationChange={handleLocationChange} /> */}
              <input
                type="text"
                className="input-filter-search"
                placeholder="Job title or company"
                name="keyword"
                value={search}
                style={{ background: '#fff', color: '#000' }}
                onChange={handleChange}
              />
            </div>
            <div className="form-group-4">
              <button type="submit" className="btn btn-find" disabled={loading}>
                {loading ? "Loading..." : "Find Jobs"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormContent;
