import { configureStore } from '@reduxjs/toolkit';
import jobReducer from './reducers/jobReducer';
import loadingReducer from './reducers/loadingReducer';
import userReducer from './reducers/userReducer';
import filterReducer from './reducers/filterReducer';
import searchReducer from './reducers/searchReducer';

const store = configureStore({
    reducer: {
        jobs: jobReducer,
        user: userReducer,
        loading: loadingReducer,
        filters: filterReducer,
        search: searchReducer,
    },
});

export default store;
