import { createSlice } from '@reduxjs/toolkit';
import { setLoading } from './loadingReducer';

const jobSlice = createSlice({
    name: 'jobs',
    initialState: {
        jobs: [],
        featuredJobs: [],
        total: 0,
        singleJob: {}
    },
    reducers: {
        setJobs: (state, action) => {
            const { jobs, total } = action.payload;
            state.jobs = jobs;
            state.total = total;
        },
        setFeaturedJobs: (state, action) => {
            const { jobs } = action.payload;
            state.featuredJobs = jobs;
        },
        setSingleJob: (state, action) => {
            state.singleJob = action.payload;
        }

    },
});

export const { setJobs, setFeaturedJobs, setSingleJob } = jobSlice.actions;
export default jobSlice.reducer;
