import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Gotop from "../Components/gotop";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MapSingle from "../Components/Job/MapSingle";
import { useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import DOMPurify from "dompurify"; //Before rendering raw HTML content, it's recommended to sanitize it to prevent XSS attacks.
import { findSingleJobs } from "../redux/actions/jobActions";
import MobileMenu from "../Components/MobileMenu";
Jobsingle_v1.propTypes = {};

// Function to check if the string contains HTML
const containsHTML = (str) => {
  return /<\/?[a-z][\s\S]*>/i.test(str);
};
const JobDescription = ({ description }) => {
  // Check if description contains HTML tags
  if (containsHTML(description)) {
    const sanitizedDescription = DOMPurify.sanitize(description); // Sanitize the HTML content

    return <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />;
  }

  // If it's plain text, render it normally
  return <p>{description}</p>;
};
function Jobsingle_v1(props) {
  const dispatch = useDispatch()
  const { jobID } = useParams(); // Get jobId from the URL
  console.log('jobId: ', jobID);
  const marKers = [];
  // Get job data from Redux store
  const job = useSelector((state) => state.jobs.singleJob);
  // const job = jobs.find((job) => job.jobID == jobID);
  console.log('job: ', job);
  marKers.push(job);

  const progressRef = useRef();
  const [targetHeight, setTargetHeight] = useState(0);

  const [isShowMobile, setShowMobile] = useState(false);

  useEffect(() => {
    dispatch(findSingleJobs(jobID))
  }, [])


  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    if (progressRef?.current) {
      const offsetHeight = progressRef?.current?.offsetTop;
      setTargetHeight(offsetHeight);
    }
  }, [progressRef]);
  const renderContractTypeLink = (type) => {
    switch (type) {
      case "1":
      case "Full-time":
        return <Link to="#">Full Time</Link>;
      case "2":
      case "Part-time":
        return <Link to="#">Part Time</Link>;
      case "3":
        return <Link to="#">Shift Work</Link>;
      case "4":
        return <Link to="#">Intermittent</Link>;
      case "5":
        return <Link to="#">Job Sharing</Link>;
      case "6":
        return <Link to="#">Multiple Schedules</Link>;
      case "permanent":
        return <Link to="#">Permanent </Link>;
      case "contract":
        return <Link to="#">Contract</Link>;
      default:
        return null; // Or handle unknown types if needed
    }
  };
  if (!job) return <p>No job found</p>;
  return (
    <>
      <MobileMenu />

      <Navbar clname="act1" handleMobile={handleMobile} />

      <section className="single-job-thumb">
        <img src={require("../Components/singlejob.png")} alt="images" />
      </section>

      <section className="form-sticky fixed-space">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wd-job-author2">
                <div className="content-left">
                  <div className="thumb">
                    <img
                      src={require("../Components/fulllogo_transparent_nobuffer 4.png")}
                      alt="logo"
                    />
                  </div>
                  <div className="content">
                    <Link to="#" className="category">
                      {job.category}
                    </Link>
                    <h6>
                      <Link to="#">
                        {job.title}
                        <span className="icon-bolt"></span>
                      </Link>
                    </h6>
                    <ul className="job-info">
                      <li>
                        <span className="icon-map-pin"></span>
                        <span>{job.location}</span>
                      </li>
                      <li>
                        <span className="icon-calendar"></span>
                        <span>{new Date(job.exactPostedDate).toDateString()}</span>
                      </li>
                    </ul>
                    <ul className="tags">
                      {renderContractTypeLink(job.jobType)}
                    </ul>
                  </div>
                </div>
                <div className="content-right">
                  <div className="top">
                    <Link to="#" className="share">
                      <i className="icon-share2" />
                    </Link>
                    <Link to="#" className="wishlist">
                      <i className="icon-heart" />
                    </Link>
                    <Link
                      to='#'
                      className="btn btn-popup"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior
                        window.open(
                          job.link,
                          "_blank",
                          "noopener,noreferrer"
                        ); // Open in new tab
                      }}
                    >
                      <i className="icon-send" />
                      Apply Now
                    </Link>
                  </div>
                  <div className="bottom">
                    <div className="gr-rating">
                      <p>Company: {job.company}</p>
                      <ul className="list-star">
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                      </ul>
                    </div>
                    <Link
                      to='#'
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior
                        window.open(
                          job.link,
                          "_blank",
                          "noopener,noreferrer"
                        ); // Open in new tab
                      }}
                    >
                      <div className="price">
                        <span className="icon-dolar1"></span>{" "}
                        <p
                          className={
                            !job.salary || typeof job.salary === "string"
                              ? "blurred-salary"
                              : ""
                          }
                        >
                          {!job.salary || typeof job.salary === "string"
                            ? "Blurred Salary"
                            : `${job.salary} /year`}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-jobs-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-8">
              <Tabs className="job-article tf-tab single-job">
                <TabList className="menu-tab">
                  <Tab className="ct-tab">About</Tab>
                  {/* <Tab className="ct-tab">Jobs (2)</Tab>
                  <Tab className="ct-tab">reviews</Tab> */}
                </TabList>
                <div className="content-tab">
                  <TabPanel className="inner-content animation-tab">
                    <h5>Full Job Description</h5>
                    <p>{/* {job.description} */}</p>
                    <JobDescription description={job.description} />
                    {/* <div dangerouslySetInnerHTML={{ __html: job.description }}/> */}
                    <Link
                      to='#'
                      style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                      onClick={() => window.open(job.link, "_blank", "noopener,noreferrer")}
                    >
                      Click to see full job description
                    </Link>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
            <div className="col-lg-4">
              <div className="cv-form-details po-sticky job-sg single-stick">
                <MapSingle marKers={marKers} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Gotop />
    </>
  );
}

export default Jobsingle_v1;
