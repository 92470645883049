import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import logo from './logo.jpeg'
import { useDispatch } from 'react-redux';
import { resetFilters } from '../redux/reducers/filterReducer';
import { resetSearch } from '../redux/reducers/searchReducer';

const MobileMenu = () => {

    const dispatch = useDispatch()

    const [isShowMobile, setShowMobile] = useState(false);

    const [username, setUsername] = useState(null);
    const [toggle, setToggle] = useState({
        key: "",
        status: false,
    });

    // Check if user is logged in by checking the token in localStorage
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const email = localStorage.getItem("email"); // Assuming email is saved when user logs in
            if (email) {
                const user = email.split("@")[0]; // Get the part before '@'
                setUsername(user);
            }
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        setUsername(null);
    };

    const handleMobile = () => {
        const getMobile = document.querySelector(".menu-mobile-popup");
        setShowMobile(!isShowMobile);
        !isShowMobile
            ? getMobile.classList.add("modal-menu--open")
            : getMobile.classList.remove("modal-menu--open");
    };

    const handleToggle = (key) => {
        if (toggle.key === key) {
            setToggle({
                status: false,
            });
        } else {
            setToggle({
                status: true,
                key,
            });
        }
    };

    return (
        <div className="menu-mobile-popup">
            <div className="modal-menu__backdrop" onClick={handleMobile}></div>
            <div className="widget-filter">
                <div className="mobile-header">
                    <div id="logo" className="logo">
                        <Link to="/">
                            <img className="site-logo" src={logo} alt="..." />
                        </Link>
                    </div>
                    <Link className="title-button-group" onClick={handleMobile}>
                        <i className="icon-close"></i>
                    </Link>
                </div>

                <Tabs className="tf-tab">
                    <TabList className="menu-tab d-flex">
                        <Tab className="user-tag flex-grow-1">Menu</Tab>
                    </TabList>

                    <div className="content-tab">
                        <TabPanel className="header-ct-center menu-moblie animation-tab">
                            <div className="nav-wrap">
                                <nav className="main-nav mobile">
                                    <ul id="menu-primary-menu" className="menu">
                                        <li className="menu-item">
                                            <Link
                                                to="/"
                                                className="iteam-menu"
                                                onClick={() => {
                                                    dispatch(resetFilters())
                                                    dispatch(resetSearch())
                                                    handleToggle("job");
                                                }}
                                            >
                                                About Us
                                            </Link>
                                        </li>
                                        <li className="menu-item current-item">
                                            <Link
                                                to="/job-board"
                                                onClick={() => {
                                                    dispatch(resetFilters())
                                                    dispatch(resetSearch())
                                                    handleToggle("home");
                                                }}
                                                className="iteam-menu"

                                            >
                                                Job Board
                                            </Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link
                                                to="/PPM Tools"
                                                className="iteam-menu"
                                                onClick={() => {
                                                    dispatch(resetFilters())
                                                    dispatch(resetSearch())
                                                    handleToggle("employers");
                                                }}
                                            >
                                                PPM Tools
                                            </Link>
                                        </li>

                                        {/* <li className="menu-item">
                    <Link
                      to="/AI-Resume-Editor/Job-Matcher"
                      className="iteam-menu"
                      onClick={() => {
                        handleToggle("employers");
                      }}
                    >
                       Eli - AI
                    </Link>
                  </li>

                  <li className="menu-item">
                    <Link
                      to="/reviews"
                      className="iteam-menu"
                      onClick={() => {
                        handleToggle("employers");
                      }}
                    >
                      Reviews
                    </Link>
                  </li> */}

                                        <li className="menu-item">
                                            <Link
                                                to="https://thepmnetworkalliance.beehiiv.com/"
                                                className="iteam-menu"
                                                onClick={() => {
                                                    handleToggle("employers");
                                                }}
                                            >
                                                Newsletter
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
                <div className="header-customize-item button">
                    {/* Conditionally render login link or username */}
                    {username ? (
                        <>
                            {username && <button onClick={() => handleLogout()}>Logout</button>}
                            <div>Welcome, {username}</div>
                        </>
                    ) : (
                        <div className="button">
                            <Link to="/login"
                                onClick={() => { dispatch(resetFilters()); dispatch(resetSearch()) }}
                            >Login</Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MobileMenu