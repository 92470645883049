import React from "react";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import Review from "../Components/breadcrumb/Review";
import Iconbox3 from "../Components/breadcrumb/Iconbox3";

import Gotop from "../Components/gotop";
import logo from "../Components/logo.jpeg";

import { Link } from "react-router-dom";

import img1 from "../assets/images/job/1.png";
import img2 from "../assets/images/job/2.png";


import img3 from "../assets/images/job/3.png";
import img4 from "../assets/images/job/4.png";

import { useState } from "react";
import MobileMenu from "../Components/MobileMenu";

AboutUs.propTypes = {};

function AboutUs() {

  const [isShowMobile, setShowMobile] = useState(false);

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };
  return (
    <>
      <MobileMenu />

      <Navbar clname="act1" handleMobile={handleMobile} />

      <Review />
      <section className="wd-iconbox style-3 inner-iconbox-section">
        <div className="tf-container">
          <div className="title-iconbox style-3 stc">
            <h4>What We Offer our Members</h4>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="group-col-3 d-flex justify-content-evenly">
                <div className="tf-iconbox style-3 cl3 stc2">
                  <div className="box-header">
                    <div className="img1">
                      <img src={img1} alt="Tailored Job Opportunities" />
                    </div>
                  </div>
                  <div className="box-content">
                    <h6 className="box-title">
                      <Link to="/Blogsingle_v1">Newsletter</Link>
                    </h6>
                    <p>
                      Stay Updated with PM Network Alliance Newsletter: Our
                      monthly newsletter keeps you informed on the latest
                      trends, news, and insights within the project, program,
                      and product management fields. Each edition covers a range
                      of topics, from industry updates to tips on leveraging AI
                      in project management.
                    </p>
                  </div>
                </div>
                <div className="tf-iconbox style-3 cl3 stc2">
                  <div className="box-header">
                    <div className="img1">
                      <img src={img2} alt="Personalized Career Support" />
                    </div>
                  </div>
                  <div className="box-content">
                    <h6 className="box-title">
                      <Link to="/Blogsingle_v1">
                        Meetups and Networking Events
                      </Link>
                    </h6>
                    <p>
                      Connect and Grow Your Network: Our regular meetups and
                      networking events provide you with the opportunity to
                      connect with peers, share knowledge, and stay ahead of
                      industry trends. These events, both virtual and in-person,
                      are designed to foster meaningful connections and
                      discussions that can enhance your career.
                    </p>
                  </div>
                </div>
                <div className="tf-iconbox style-3 cl3 stc2">
                  <div className="box-header">
                    <div className="img1">
                      <img src={img3} alt="Exclusive Networking Events" />
                    </div>
                  </div>
                  <div className="box-content">
                    <h6 className="box-title">
                      <Link to="/Blogsingle_v1">PPM Tool Access & Reviews</Link>
                    </h6>
                    <p>
                      Access In-Depth Reviews and Tools for PPM Success: We
                      provide comprehensive reviews and insights on the latest
                      Project, Program, and Product Management (PPM) tools.
                      Access our curated list of tools and read expert reviews
                      to help you choose the right solutions for your needs.
                      [Link to PPM Tool Reviews]
                    </p>
                  </div>
                </div>

                <div className="tf-iconbox style-3 cl3 stc2 mt-4">
                  <div className="box-header">
                    <div className="img1">
                      <img src={img4} alt="Exclusive Networking Events" />
                    </div>
                  </div>
                  <div className="box-content">
                    <h6 className="box-title">
                      <Link to="/Blogsingle_v1">Job Board</Link>
                    </h6>
                    <p>
                      Explore Tailored Job Opportunities for PM, PPM, and
                      Product Roles: Our job board consolidates roles across
                      project, program, and product management, giving you
                      access to exclusive opportunities from our network of
                      industry partners. Browse and apply for positions that
                      match your skills and career goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Gotop />
    </>
  );
}

export default AboutUs;
