import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Banner01 from "../Components/Banner/Banner01";
import Category from "../Components/category";

import Jobs from "../Components/Job/Job";

import Gotop from "../Components/gotop";

import { findJobs } from "../redux/actions/jobActions";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../redux/reducers/filterReducer";
import MobileMenu from "../Components/MobileMenu";

Home_v1.propTypes = {};

function Home_v1() {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filters)
  const [prevQuery, setPrevQuery] = useState('')


  useEffect(() => {
    let query = `isFeatured=true&`
    for (const key in filters) {
      if (filters[key]) {
        query += `${key}=${filters[key]}&`
      }
    }
    if (query !== prevQuery)
      dispatch(findJobs({ query, setPrevQuery }))
  }, [filters])


  const [isShowMobile, setShowMobile] = useState(false);

  const changeFilter = ({ name, value }) => {
    dispatch(setFilters({ name, value }))
  }

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();
  }, []);

  return (
    <>
      <MobileMenu />

      <Navbar clname="act1" handleMobile={handleMobile} />
      <Banner01 />
      <Category
        changeFilter={changeFilter}
        className="job-category-section"
      />

      <Jobs className="jobs-section-three" />

      <Footer />

      <Gotop />
    </>
  );
}

export default Home_v1;
