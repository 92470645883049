import React from "react";
import Dropdown from "react-dropdown";

const options = [
  { value: 12, label: "12 / Page" },
  { value: 1, label: "1 / Page" },
  { value: 50, label: "50 /Page" },
];

const options2 = [
  { value: -1, label: "Sort by (New)" },
  { value: 1, label: "Sort by (Last)" },
];

function SortBuy({ handleFilterChange }) {

  const handleSortChange = (option) => {
    handleFilterChange({ name: 'sortOrder', value: option.value })
  };

  const handleResultsPerPageChange = (option) => {
    handleFilterChange({ name: 'resultsPerPage', value: option.value })
    handleFilterChange({ name: 'currentPage', value: 1 })
  };

  return (
    <div className="group-select">
      <Dropdown
        options={options}
        className="react-dropdown sort-buy"
        value={options[0]}
        onChange={handleResultsPerPageChange}
      />
      {/* <CustomDropdown
        isSearchable={false}
        options={options}
      onChange={option =>
        option ? changeFilter({ name: 'resultsPerPage', value: option.value }) : changeFilter({ name: 'resultsPerPage', value: '' })}
      /> */}
      <Dropdown
        options={options2}
        className="react-dropdown sort-buy"
        value={options2[0]}
        onChange={handleSortChange}
      />
      {/* <CustomDropdown
        isSearchable={false}
        options={options2}
      onChange={option =>
        option ? changeFilter({ name: 'sortOrder', value: option.value }) : changeFilter({ name: 'sortOrder', value: '' })}
      /> */}
    </div>
  );
}

export default SortBuy;
