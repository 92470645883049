import { createSlice } from '@reduxjs/toolkit';

const initialState = ''

const searchSlice = createSlice({
    name: 'search',
    initialState, // Initially, we are not loading
    reducers: {
        setSearch: (state, action) => action.payload,
        resetSearch: () => initialState
    },
});

// Export the action creator
export const { setSearch, resetSearch } = searchSlice.actions;
// Export the reducer
export default searchSlice.reducer;
