import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Gotop from "../Components/gotop";

import FaqItem from "../Components/faq";
import dataFaq from "../assets/fakeData/dataFaq";
import { useState } from "react";
import MobileMenu from "../Components/MobileMenu";

function PPMTools(props) {
  const [isShowMobile, setShowMobile] = useState(false);

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };
  return (
    <>
      <MobileMenu />
      <Navbar clname="act1" handleMobile={handleMobile} />
      <FaqItem data={dataFaq} />
      <Footer />
      <Gotop />
    </>
  );
}

export default PPMTools;
