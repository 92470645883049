export const jobTypeOptions = [
    // { value: "", label: "All Job Types" },
    { value: "On-site", label: 'On-site' },
    { value: "Hybrid", label: 'Hybrid' },
    { value: "Remote", label: 'Remote' },
];

export const jobCategoryOptions = [
    // { value: '', label: '' },
    { value: 'Product Manager', label: 'Product Manager Roles' },
    { value: 'Program Manager', label: 'Program Manager Roles' },
    { value: 'Project Coordinator', label: 'Project Coordinator Roles' },
    { value: 'Project Manager', label: 'Project Manager Roles' },
    { value: 'Other', label: 'Others' },
]

export const postedTimeOptions = [
    // { value: "", label: "Posted Anytime" },
    { value: "lastDay", label: "Last 24 hours" },
    { value: "lastWeek", label: "Last Week" },
    { value: "lastMonth", label: "Last month" },
];