export const usCities = [
    {
        "value": "New York",
        "label": "New York"
    },
    {
        "value": "Buffalo",
        "label": "Buffalo"
    },
    {
        "value": "Rochester",
        "label": "Rochester"
    },
    {
        "value": "Yonkers",
        "label": "Yonkers"
    },
    {
        "value": "Syracuse",
        "label": "Syracuse"
    },
    {
        "value": "Albany",
        "label": "Albany"
    },
    {
        "value": "New Rochelle",
        "label": "New Rochelle"
    },
    {
        "value": "Mount Vernon",
        "label": "Mount Vernon"
    },
    {
        "value": "Schenectady",
        "label": "Schenectady"
    },
    {
        "value": "Utica",
        "label": "Utica"
    },
    {
        "value": "White Plains",
        "label": "White Plains"
    },
    {
        "value": "Hempstead",
        "label": "Hempstead"
    },
    {
        "value": "Troy",
        "label": "Troy"
    },
    {
        "value": "Niagara Falls",
        "label": "Niagara Falls"
    },
    {
        "value": "Binghamton",
        "label": "Binghamton"
    },
    {
        "value": "Freeport",
        "label": "Freeport"
    },
    {
        "value": "Valley Stream",
        "label": "Valley Stream"
    },
    {
        "value": "Los Angeles",
        "label": "Los Angeles"
    },
    {
        "value": "San Diego",
        "label": "San Diego"
    },
    {
        "value": "San Jose",
        "label": "San Jose"
    },
    {
        "value": "San Francisco",
        "label": "San Francisco"
    },
    {
        "value": "Fresno",
        "label": "Fresno"
    },
    {
        "value": "Sacramento",
        "label": "Sacramento"
    },
    {
        "value": "Long Beach",
        "label": "Long Beach"
    },
    {
        "value": "Oakland",
        "label": "Oakland"
    },
    {
        "value": "Bakersfield",
        "label": "Bakersfield"
    },
    {
        "value": "Anaheim",
        "label": "Anaheim"
    },
    {
        "value": "Santa Ana",
        "label": "Santa Ana"
    },
    {
        "value": "Riverside",
        "label": "Riverside"
    },
    {
        "value": "Stockton",
        "label": "Stockton"
    },
    {
        "value": "Chula Vista",
        "label": "Chula Vista"
    },
    {
        "value": "Irvine",
        "label": "Irvine"
    },
    {
        "value": "Fremont",
        "label": "Fremont"
    },
    {
        "value": "San Bernardino",
        "label": "San Bernardino"
    },
    {
        "value": "Modesto",
        "label": "Modesto"
    },
    {
        "value": "Fontana",
        "label": "Fontana"
    },
    {
        "value": "Oxnard",
        "label": "Oxnard"
    },
    {
        "value": "Moreno Valley",
        "label": "Moreno Valley"
    },
    {
        "value": "Huntington Beach",
        "label": "Huntington Beach"
    },
    {
        "value": "Glendale",
        "label": "Glendale"
    },
    {
        "value": "Santa Clarita",
        "label": "Santa Clarita"
    },
    {
        "value": "Garden Grove",
        "label": "Garden Grove"
    },
    {
        "value": "Oceanside",
        "label": "Oceanside"
    },
    {
        "value": "Rancho Cucamonga",
        "label": "Rancho Cucamonga"
    },
    {
        "value": "Santa Rosa",
        "label": "Santa Rosa"
    },
    {
        "value": "Ontario",
        "label": "Ontario"
    },
    {
        "value": "Lancaster",
        "label": "Lancaster"
    },
    {
        "value": "Elk Grove",
        "label": "Elk Grove"
    },
    {
        "value": "Corona",
        "label": "Corona"
    },
    {
        "value": "Palmdale",
        "label": "Palmdale"
    },
    {
        "value": "Salinas",
        "label": "Salinas"
    },
    {
        "value": "Pomona",
        "label": "Pomona"
    },
    {
        "value": "Hayward",
        "label": "Hayward"
    },
    {
        "value": "Escondido",
        "label": "Escondido"
    },
    {
        "value": "Torrance",
        "label": "Torrance"
    },
    {
        "value": "Sunnyvale",
        "label": "Sunnyvale"
    },
    {
        "value": "Orange",
        "label": "Orange"
    },
    {
        "value": "Fullerton",
        "label": "Fullerton"
    },
    {
        "value": "Pasadena",
        "label": "Pasadena"
    },
    {
        "value": "Thousand Oaks",
        "label": "Thousand Oaks"
    },
    {
        "value": "Visalia",
        "label": "Visalia"
    },
    {
        "value": "Simi Valley",
        "label": "Simi Valley"
    },
    {
        "value": "Concord",
        "label": "Concord"
    },
    {
        "value": "Roseville",
        "label": "Roseville"
    },
    {
        "value": "Victorville",
        "label": "Victorville"
    },
    {
        "value": "Santa Clara",
        "label": "Santa Clara"
    },
    {
        "value": "Vallejo",
        "label": "Vallejo"
    },
    {
        "value": "Berkeley",
        "label": "Berkeley"
    },
    {
        "value": "El Monte",
        "label": "El Monte"
    },
    {
        "value": "Downey",
        "label": "Downey"
    },
    {
        "value": "Costa Mesa",
        "label": "Costa Mesa"
    },
    {
        "value": "Inglewood",
        "label": "Inglewood"
    },
    {
        "value": "Carlsbad",
        "label": "Carlsbad"
    },
    {
        "value": "San Buenaventura (Ventura)",
        "label": "San Buenaventura (Ventura)"
    },
    {
        "value": "Fairfield",
        "label": "Fairfield"
    },
    {
        "value": "West Covina",
        "label": "West Covina"
    },
    {
        "value": "Murrieta",
        "label": "Murrieta"
    },
    {
        "value": "Richmond",
        "label": "Richmond"
    },
    {
        "value": "Norwalk",
        "label": "Norwalk"
    },
    {
        "value": "Antioch",
        "label": "Antioch"
    },
    {
        "value": "Temecula",
        "label": "Temecula"
    },
    {
        "value": "Burbank",
        "label": "Burbank"
    },
    {
        "value": "Daly City",
        "label": "Daly City"
    },
    {
        "value": "Rialto",
        "label": "Rialto"
    },
    {
        "value": "Santa Maria",
        "label": "Santa Maria"
    },
    {
        "value": "El Cajon",
        "label": "El Cajon"
    },
    {
        "value": "San Mateo",
        "label": "San Mateo"
    },
    {
        "value": "Clovis",
        "label": "Clovis"
    },
    {
        "value": "Compton",
        "label": "Compton"
    },
    {
        "value": "Jurupa Valley",
        "label": "Jurupa Valley"
    },
    {
        "value": "Vista",
        "label": "Vista"
    },
    {
        "value": "South Gate",
        "label": "South Gate"
    },
    {
        "value": "Mission Viejo",
        "label": "Mission Viejo"
    },
    {
        "value": "Vacaville",
        "label": "Vacaville"
    },
    {
        "value": "Carson",
        "label": "Carson"
    },
    {
        "value": "Hesperia",
        "label": "Hesperia"
    },
    {
        "value": "Santa Monica",
        "label": "Santa Monica"
    },
    {
        "value": "Westminster",
        "label": "Westminster"
    },
    {
        "value": "Redding",
        "label": "Redding"
    },
    {
        "value": "Santa Barbara",
        "label": "Santa Barbara"
    },
    {
        "value": "Chico",
        "label": "Chico"
    },
    {
        "value": "Newport Beach",
        "label": "Newport Beach"
    },
    {
        "value": "San Leandro",
        "label": "San Leandro"
    },
    {
        "value": "San Marcos",
        "label": "San Marcos"
    },
    {
        "value": "Whittier",
        "label": "Whittier"
    },
    {
        "value": "Hawthorne",
        "label": "Hawthorne"
    },
    {
        "value": "Citrus Heights",
        "label": "Citrus Heights"
    },
    {
        "value": "Tracy",
        "label": "Tracy"
    },
    {
        "value": "Alhambra",
        "label": "Alhambra"
    },
    {
        "value": "Livermore",
        "label": "Livermore"
    },
    {
        "value": "Buena Park",
        "label": "Buena Park"
    },
    {
        "value": "Menifee",
        "label": "Menifee"
    },
    {
        "value": "Hemet",
        "label": "Hemet"
    },
    {
        "value": "Lakewood",
        "label": "Lakewood"
    },
    {
        "value": "Merced",
        "label": "Merced"
    },
    {
        "value": "Chino",
        "label": "Chino"
    },
    {
        "value": "Indio",
        "label": "Indio"
    },
    {
        "value": "Redwood City",
        "label": "Redwood City"
    },
    {
        "value": "Lake Forest",
        "label": "Lake Forest"
    },
    {
        "value": "Napa",
        "label": "Napa"
    },
    {
        "value": "Tustin",
        "label": "Tustin"
    },
    {
        "value": "Bellflower",
        "label": "Bellflower"
    },
    {
        "value": "Mountain View",
        "label": "Mountain View"
    },
    {
        "value": "Chino Hills",
        "label": "Chino Hills"
    },
    {
        "value": "Baldwin Park",
        "label": "Baldwin Park"
    },
    {
        "value": "Alameda",
        "label": "Alameda"
    },
    {
        "value": "Upland",
        "label": "Upland"
    },
    {
        "value": "San Ramon",
        "label": "San Ramon"
    },
    {
        "value": "Folsom",
        "label": "Folsom"
    },
    {
        "value": "Pleasanton",
        "label": "Pleasanton"
    },
    {
        "value": "Union City",
        "label": "Union City"
    },
    {
        "value": "Perris",
        "label": "Perris"
    },
    {
        "value": "Manteca",
        "label": "Manteca"
    },
    {
        "value": "Lynwood",
        "label": "Lynwood"
    },
    {
        "value": "Apple Valley",
        "label": "Apple Valley"
    },
    {
        "value": "Redlands",
        "label": "Redlands"
    },
    {
        "value": "Turlock",
        "label": "Turlock"
    },
    {
        "value": "Milpitas",
        "label": "Milpitas"
    },
    {
        "value": "Redondo Beach",
        "label": "Redondo Beach"
    },
    {
        "value": "Rancho Cordova",
        "label": "Rancho Cordova"
    },
    {
        "value": "Yorba Linda",
        "label": "Yorba Linda"
    },
    {
        "value": "Palo Alto",
        "label": "Palo Alto"
    },
    {
        "value": "Davis",
        "label": "Davis"
    },
    {
        "value": "Camarillo",
        "label": "Camarillo"
    },
    {
        "value": "Walnut Creek",
        "label": "Walnut Creek"
    },
    {
        "value": "Pittsburg",
        "label": "Pittsburg"
    },
    {
        "value": "South San Francisco",
        "label": "South San Francisco"
    },
    {
        "value": "Yuba City",
        "label": "Yuba City"
    },
    {
        "value": "San Clemente",
        "label": "San Clemente"
    },
    {
        "value": "Laguna Niguel",
        "label": "Laguna Niguel"
    },
    {
        "value": "Pico Rivera",
        "label": "Pico Rivera"
    },
    {
        "value": "Montebello",
        "label": "Montebello"
    },
    {
        "value": "Lodi",
        "label": "Lodi"
    },
    {
        "value": "Madera",
        "label": "Madera"
    },
    {
        "value": "Santa Cruz",
        "label": "Santa Cruz"
    },
    {
        "value": "La Habra",
        "label": "La Habra"
    },
    {
        "value": "Encinitas",
        "label": "Encinitas"
    },
    {
        "value": "Monterey Park",
        "label": "Monterey Park"
    },
    {
        "value": "Tulare",
        "label": "Tulare"
    },
    {
        "value": "Cupertino",
        "label": "Cupertino"
    },
    {
        "value": "Gardena",
        "label": "Gardena"
    },
    {
        "value": "National City",
        "label": "National City"
    },
    {
        "value": "Rocklin",
        "label": "Rocklin"
    },
    {
        "value": "Petaluma",
        "label": "Petaluma"
    },
    {
        "value": "Huntington Park",
        "label": "Huntington Park"
    },
    {
        "value": "San Rafael",
        "label": "San Rafael"
    },
    {
        "value": "La Mesa",
        "label": "La Mesa"
    },
    {
        "value": "Arcadia",
        "label": "Arcadia"
    },
    {
        "value": "Fountain Valley",
        "label": "Fountain Valley"
    },
    {
        "value": "Diamond Bar",
        "label": "Diamond Bar"
    },
    {
        "value": "Woodland",
        "label": "Woodland"
    },
    {
        "value": "Santee",
        "label": "Santee"
    },
    {
        "value": "Lake Elsinore",
        "label": "Lake Elsinore"
    },
    {
        "value": "Porterville",
        "label": "Porterville"
    },
    {
        "value": "Paramount",
        "label": "Paramount"
    },
    {
        "value": "Eastvale",
        "label": "Eastvale"
    },
    {
        "value": "Rosemead",
        "label": "Rosemead"
    },
    {
        "value": "Hanford",
        "label": "Hanford"
    },
    {
        "value": "Highland",
        "label": "Highland"
    },
    {
        "value": "Brentwood",
        "label": "Brentwood"
    },
    {
        "value": "Novato",
        "label": "Novato"
    },
    {
        "value": "Colton",
        "label": "Colton"
    },
    {
        "value": "Cathedral City",
        "label": "Cathedral City"
    },
    {
        "value": "Delano",
        "label": "Delano"
    },
    {
        "value": "Yucaipa",
        "label": "Yucaipa"
    },
    {
        "value": "Watsonville",
        "label": "Watsonville"
    },
    {
        "value": "Placentia",
        "label": "Placentia"
    },
    {
        "value": "Glendora",
        "label": "Glendora"
    },
    {
        "value": "Gilroy",
        "label": "Gilroy"
    },
    {
        "value": "Palm Desert",
        "label": "Palm Desert"
    },
    {
        "value": "Cerritos",
        "label": "Cerritos"
    },
    {
        "value": "West Sacramento",
        "label": "West Sacramento"
    },
    {
        "value": "Aliso Viejo",
        "label": "Aliso Viejo"
    },
    {
        "value": "Poway",
        "label": "Poway"
    },
    {
        "value": "La Mirada",
        "label": "La Mirada"
    },
    {
        "value": "Rancho Santa Margarita",
        "label": "Rancho Santa Margarita"
    },
    {
        "value": "Cypress",
        "label": "Cypress"
    },
    {
        "value": "Dublin",
        "label": "Dublin"
    },
    {
        "value": "Covina",
        "label": "Covina"
    },
    {
        "value": "Azusa",
        "label": "Azusa"
    },
    {
        "value": "Palm Springs",
        "label": "Palm Springs"
    },
    {
        "value": "San Luis Obispo",
        "label": "San Luis Obispo"
    },
    {
        "value": "Ceres",
        "label": "Ceres"
    },
    {
        "value": "San Jacinto",
        "label": "San Jacinto"
    },
    {
        "value": "Lincoln",
        "label": "Lincoln"
    },
    {
        "value": "Newark",
        "label": "Newark"
    },
    {
        "value": "Lompoc",
        "label": "Lompoc"
    },
    {
        "value": "El Centro",
        "label": "El Centro"
    },
    {
        "value": "Danville",
        "label": "Danville"
    },
    {
        "value": "Bell Gardens",
        "label": "Bell Gardens"
    },
    {
        "value": "Coachella",
        "label": "Coachella"
    },
    {
        "value": "Rancho Palos Verdes",
        "label": "Rancho Palos Verdes"
    },
    {
        "value": "San Bruno",
        "label": "San Bruno"
    },
    {
        "value": "Rohnert Park",
        "label": "Rohnert Park"
    },
    {
        "value": "Brea",
        "label": "Brea"
    },
    {
        "value": "La Puente",
        "label": "La Puente"
    },
    {
        "value": "Campbell",
        "label": "Campbell"
    },
    {
        "value": "San Gabriel",
        "label": "San Gabriel"
    },
    {
        "value": "Beaumont",
        "label": "Beaumont"
    },
    {
        "value": "Morgan Hill",
        "label": "Morgan Hill"
    },
    {
        "value": "Culver City",
        "label": "Culver City"
    },
    {
        "value": "Calexico",
        "label": "Calexico"
    },
    {
        "value": "Stanton",
        "label": "Stanton"
    },
    {
        "value": "La Quinta",
        "label": "La Quinta"
    },
    {
        "value": "Pacifica",
        "label": "Pacifica"
    },
    {
        "value": "Montclair",
        "label": "Montclair"
    },
    {
        "value": "Oakley",
        "label": "Oakley"
    },
    {
        "value": "Monrovia",
        "label": "Monrovia"
    },
    {
        "value": "Los Banos",
        "label": "Los Banos"
    },
    {
        "value": "Martinez",
        "label": "Martinez"
    },
    {
        "value": "Chicago",
        "label": "Chicago"
    },
    {
        "value": "Aurora",
        "label": "Aurora"
    },
    {
        "value": "Rockford",
        "label": "Rockford"
    },
    {
        "value": "Joliet",
        "label": "Joliet"
    },
    {
        "value": "Naperville",
        "label": "Naperville"
    },
    {
        "value": "Springfield",
        "label": "Springfield"
    },
    {
        "value": "Peoria",
        "label": "Peoria"
    },
    {
        "value": "Elgin",
        "label": "Elgin"
    },
    {
        "value": "Waukegan",
        "label": "Waukegan"
    },
    {
        "value": "Cicero",
        "label": "Cicero"
    },
    {
        "value": "Champaign",
        "label": "Champaign"
    },
    {
        "value": "Bloomington",
        "label": "Bloomington"
    },
    {
        "value": "Arlington Heights",
        "label": "Arlington Heights"
    },
    {
        "value": "Evanston",
        "label": "Evanston"
    },
    {
        "value": "Decatur",
        "label": "Decatur"
    },
    {
        "value": "Schaumburg",
        "label": "Schaumburg"
    },
    {
        "value": "Bolingbrook",
        "label": "Bolingbrook"
    },
    {
        "value": "Palatine",
        "label": "Palatine"
    },
    {
        "value": "Skokie",
        "label": "Skokie"
    },
    {
        "value": "Des Plaines",
        "label": "Des Plaines"
    },
    {
        "value": "Orland Park",
        "label": "Orland Park"
    },
    {
        "value": "Tinley Park",
        "label": "Tinley Park"
    },
    {
        "value": "Oak Lawn",
        "label": "Oak Lawn"
    },
    {
        "value": "Berwyn",
        "label": "Berwyn"
    },
    {
        "value": "Mount Prospect",
        "label": "Mount Prospect"
    },
    {
        "value": "Normal",
        "label": "Normal"
    },
    {
        "value": "Wheaton",
        "label": "Wheaton"
    },
    {
        "value": "Hoffman Estates",
        "label": "Hoffman Estates"
    },
    {
        "value": "Oak Park",
        "label": "Oak Park"
    },
    {
        "value": "Downers Grove",
        "label": "Downers Grove"
    },
    {
        "value": "Elmhurst",
        "label": "Elmhurst"
    },
    {
        "value": "Glenview",
        "label": "Glenview"
    },
    {
        "value": "DeKalb",
        "label": "DeKalb"
    },
    {
        "value": "Lombard",
        "label": "Lombard"
    },
    {
        "value": "Belleville",
        "label": "Belleville"
    },
    {
        "value": "Moline",
        "label": "Moline"
    },
    {
        "value": "Buffalo Grove",
        "label": "Buffalo Grove"
    },
    {
        "value": "Bartlett",
        "label": "Bartlett"
    },
    {
        "value": "Urbana",
        "label": "Urbana"
    },
    {
        "value": "Quincy",
        "label": "Quincy"
    },
    {
        "value": "Crystal Lake",
        "label": "Crystal Lake"
    },
    {
        "value": "Plainfield",
        "label": "Plainfield"
    },
    {
        "value": "Streamwood",
        "label": "Streamwood"
    },
    {
        "value": "Carol Stream",
        "label": "Carol Stream"
    },
    {
        "value": "Romeoville",
        "label": "Romeoville"
    },
    {
        "value": "Rock Island",
        "label": "Rock Island"
    },
    {
        "value": "Hanover Park",
        "label": "Hanover Park"
    },
    {
        "value": "Carpentersville",
        "label": "Carpentersville"
    },
    {
        "value": "Wheeling",
        "label": "Wheeling"
    },
    {
        "value": "Park Ridge",
        "label": "Park Ridge"
    },
    {
        "value": "Addison",
        "label": "Addison"
    },
    {
        "value": "Calumet City",
        "label": "Calumet City"
    },
    {
        "value": "Houston",
        "label": "Houston"
    },
    {
        "value": "San Antonio",
        "label": "San Antonio"
    },
    {
        "value": "Dallas",
        "label": "Dallas"
    },
    {
        "value": "Austin",
        "label": "Austin"
    },
    {
        "value": "Fort Worth",
        "label": "Fort Worth"
    },
    {
        "value": "El Paso",
        "label": "El Paso"
    },
    {
        "value": "Arlington",
        "label": "Arlington"
    },
    {
        "value": "Corpus Christi",
        "label": "Corpus Christi"
    },
    {
        "value": "Plano",
        "label": "Plano"
    },
    {
        "value": "Laredo",
        "label": "Laredo"
    },
    {
        "value": "Lubbock",
        "label": "Lubbock"
    },
    {
        "value": "Garland",
        "label": "Garland"
    },
    {
        "value": "Irving",
        "label": "Irving"
    },
    {
        "value": "Amarillo",
        "label": "Amarillo"
    },
    {
        "value": "Grand Prairie",
        "label": "Grand Prairie"
    },
    {
        "value": "Brownsville",
        "label": "Brownsville"
    },
    {
        "value": "Pasadena",
        "label": "Pasadena"
    },
    {
        "value": "McKinney",
        "label": "McKinney"
    },
    {
        "value": "Mesquite",
        "label": "Mesquite"
    },
    {
        "value": "McAllen",
        "label": "McAllen"
    },
    {
        "value": "Killeen",
        "label": "Killeen"
    },
    {
        "value": "Frisco",
        "label": "Frisco"
    },
    {
        "value": "Waco",
        "label": "Waco"
    },
    {
        "value": "Carrollton",
        "label": "Carrollton"
    },
    {
        "value": "Denton",
        "label": "Denton"
    },
    {
        "value": "Midland",
        "label": "Midland"
    },
    {
        "value": "Abilene",
        "label": "Abilene"
    },
    {
        "value": "Beaumont",
        "label": "Beaumont"
    },
    {
        "value": "Round Rock",
        "label": "Round Rock"
    },
    {
        "value": "Odessa",
        "label": "Odessa"
    },
    {
        "value": "Wichita Falls",
        "label": "Wichita Falls"
    },
    {
        "value": "Richardson",
        "label": "Richardson"
    },
    {
        "value": "Lewisville",
        "label": "Lewisville"
    },
    {
        "value": "Tyler",
        "label": "Tyler"
    },
    {
        "value": "College Station",
        "label": "College Station"
    },
    {
        "value": "Pearland",
        "label": "Pearland"
    },
    {
        "value": "San Angelo",
        "label": "San Angelo"
    },
    {
        "value": "Allen",
        "label": "Allen"
    },
    {
        "value": "League City",
        "label": "League City"
    },
    {
        "value": "Sugar Land",
        "label": "Sugar Land"
    },
    {
        "value": "Longview",
        "label": "Longview"
    },
    {
        "value": "Edinburg",
        "label": "Edinburg"
    },
    {
        "value": "Mission",
        "label": "Mission"
    },
    {
        "value": "Bryan",
        "label": "Bryan"
    },
    {
        "value": "Baytown",
        "label": "Baytown"
    },
    {
        "value": "Pharr",
        "label": "Pharr"
    },
    {
        "value": "Temple",
        "label": "Temple"
    },
    {
        "value": "Missouri City",
        "label": "Missouri City"
    },
    {
        "value": "Flower Mound",
        "label": "Flower Mound"
    },
    {
        "value": "Harlingen",
        "label": "Harlingen"
    },
    {
        "value": "North Richland Hills",
        "label": "North Richland Hills"
    },
    {
        "value": "Victoria",
        "label": "Victoria"
    },
    {
        "value": "Conroe",
        "label": "Conroe"
    },
    {
        "value": "New Braunfels",
        "label": "New Braunfels"
    },
    {
        "value": "Mansfield",
        "label": "Mansfield"
    },
    {
        "value": "Cedar Park",
        "label": "Cedar Park"
    },
    {
        "value": "Rowlett",
        "label": "Rowlett"
    },
    {
        "value": "Port Arthur",
        "label": "Port Arthur"
    },
    {
        "value": "Euless",
        "label": "Euless"
    },
    {
        "value": "Georgetown",
        "label": "Georgetown"
    },
    {
        "value": "Pflugerville",
        "label": "Pflugerville"
    },
    {
        "value": "DeSoto",
        "label": "DeSoto"
    },
    {
        "value": "San Marcos",
        "label": "San Marcos"
    },
    {
        "value": "Grapevine",
        "label": "Grapevine"
    },
    {
        "value": "Bedford",
        "label": "Bedford"
    },
    {
        "value": "Galveston",
        "label": "Galveston"
    },
    {
        "value": "Cedar Hill",
        "label": "Cedar Hill"
    },
    {
        "value": "Texas City",
        "label": "Texas City"
    },
    {
        "value": "Wylie",
        "label": "Wylie"
    },
    {
        "value": "Haltom City",
        "label": "Haltom City"
    },
    {
        "value": "Keller",
        "label": "Keller"
    },
    {
        "value": "Coppell",
        "label": "Coppell"
    },
    {
        "value": "Rockwall",
        "label": "Rockwall"
    },
    {
        "value": "Huntsville",
        "label": "Huntsville"
    },
    {
        "value": "Duncanville",
        "label": "Duncanville"
    },
    {
        "value": "Sherman",
        "label": "Sherman"
    },
    {
        "value": "The Colony",
        "label": "The Colony"
    },
    {
        "value": "Burleson",
        "label": "Burleson"
    },
    {
        "value": "Hurst",
        "label": "Hurst"
    },
    {
        "value": "Lancaster",
        "label": "Lancaster"
    },
    {
        "value": "Texarkana",
        "label": "Texarkana"
    },
    {
        "value": "Friendswood",
        "label": "Friendswood"
    },
    {
        "value": "Weslaco",
        "label": "Weslaco"
    },
    {
        "value": "Philadelphia",
        "label": "Philadelphia"
    },
    {
        "value": "Pittsburgh",
        "label": "Pittsburgh"
    },
    {
        "value": "Allentown",
        "label": "Allentown"
    },
    {
        "value": "Erie",
        "label": "Erie"
    },
    {
        "value": "Reading",
        "label": "Reading"
    },
    {
        "value": "Scranton",
        "label": "Scranton"
    },
    {
        "value": "Bethlehem",
        "label": "Bethlehem"
    },
    {
        "value": "Lancaster",
        "label": "Lancaster"
    },
    {
        "value": "Harrisburg",
        "label": "Harrisburg"
    },
    {
        "value": "Altoona",
        "label": "Altoona"
    },
    {
        "value": "York",
        "label": "York"
    },
    {
        "value": "State College",
        "label": "State College"
    },
    {
        "value": "Wilkes-Barre",
        "label": "Wilkes-Barre"
    },
    {
        "value": "Phoenix",
        "label": "Phoenix"
    },
    {
        "value": "Tucson",
        "label": "Tucson"
    },
    {
        "value": "Mesa",
        "label": "Mesa"
    },
    {
        "value": "Chandler",
        "label": "Chandler"
    },
    {
        "value": "Glendale",
        "label": "Glendale"
    },
    {
        "value": "Scottsdale",
        "label": "Scottsdale"
    },
    {
        "value": "Gilbert",
        "label": "Gilbert"
    },
    {
        "value": "Tempe",
        "label": "Tempe"
    },
    {
        "value": "Peoria",
        "label": "Peoria"
    },
    {
        "value": "Surprise",
        "label": "Surprise"
    },
    {
        "value": "Yuma",
        "label": "Yuma"
    },
    {
        "value": "Avondale",
        "label": "Avondale"
    },
    {
        "value": "Goodyear",
        "label": "Goodyear"
    },
    {
        "value": "Flagstaff",
        "label": "Flagstaff"
    },
    {
        "value": "Buckeye",
        "label": "Buckeye"
    },
    {
        "value": "Lake Havasu City",
        "label": "Lake Havasu City"
    },
    {
        "value": "Casa Grande",
        "label": "Casa Grande"
    },
    {
        "value": "Sierra Vista",
        "label": "Sierra Vista"
    },
    {
        "value": "Maricopa",
        "label": "Maricopa"
    },
    {
        "value": "Oro Valley",
        "label": "Oro Valley"
    },
    {
        "value": "Prescott",
        "label": "Prescott"
    },
    {
        "value": "Bullhead City",
        "label": "Bullhead City"
    },
    {
        "value": "Prescott Valley",
        "label": "Prescott Valley"
    },
    {
        "value": "Marana",
        "label": "Marana"
    },
    {
        "value": "Apache Junction",
        "label": "Apache Junction"
    },
    {
        "value": "Jacksonville",
        "label": "Jacksonville"
    },
    {
        "value": "Miami",
        "label": "Miami"
    },
    {
        "value": "Tampa",
        "label": "Tampa"
    },
    {
        "value": "Orlando",
        "label": "Orlando"
    },
    {
        "value": "St. Petersburg",
        "label": "St. Petersburg"
    },
    {
        "value": "Hialeah",
        "label": "Hialeah"
    },
    {
        "value": "Tallahassee",
        "label": "Tallahassee"
    },
    {
        "value": "Fort Lauderdale",
        "label": "Fort Lauderdale"
    },
    {
        "value": "Port St. Lucie",
        "label": "Port St. Lucie"
    },
    {
        "value": "Cape Coral",
        "label": "Cape Coral"
    },
    {
        "value": "Pembroke Pines",
        "label": "Pembroke Pines"
    },
    {
        "value": "Hollywood",
        "label": "Hollywood"
    },
    {
        "value": "Miramar",
        "label": "Miramar"
    },
    {
        "value": "Gainesville",
        "label": "Gainesville"
    },
    {
        "value": "Coral Springs",
        "label": "Coral Springs"
    },
    {
        "value": "Miami Gardens",
        "label": "Miami Gardens"
    },
    {
        "value": "Clearwater",
        "label": "Clearwater"
    },
    {
        "value": "Palm Bay",
        "label": "Palm Bay"
    },
    {
        "value": "Pompano Beach",
        "label": "Pompano Beach"
    },
    {
        "value": "West Palm Beach",
        "label": "West Palm Beach"
    },
    {
        "value": "Lakeland",
        "label": "Lakeland"
    },
    {
        "value": "Davie",
        "label": "Davie"
    },
    {
        "value": "Miami Beach",
        "label": "Miami Beach"
    },
    {
        "value": "Sunrise",
        "label": "Sunrise"
    },
    {
        "value": "Plantation",
        "label": "Plantation"
    },
    {
        "value": "Boca Raton",
        "label": "Boca Raton"
    },
    {
        "value": "Deltona",
        "label": "Deltona"
    },
    {
        "value": "Largo",
        "label": "Largo"
    },
    {
        "value": "Deerfield Beach",
        "label": "Deerfield Beach"
    },
    {
        "value": "Palm Coast",
        "label": "Palm Coast"
    },
    {
        "value": "Melbourne",
        "label": "Melbourne"
    },
    {
        "value": "Boynton Beach",
        "label": "Boynton Beach"
    },
    {
        "value": "Lauderhill",
        "label": "Lauderhill"
    },
    {
        "value": "Weston",
        "label": "Weston"
    },
    {
        "value": "Fort Myers",
        "label": "Fort Myers"
    },
    {
        "value": "Kissimmee",
        "label": "Kissimmee"
    },
    {
        "value": "Homestead",
        "label": "Homestead"
    },
    {
        "value": "Tamarac",
        "label": "Tamarac"
    },
    {
        "value": "Delray Beach",
        "label": "Delray Beach"
    },
    {
        "value": "Daytona Beach",
        "label": "Daytona Beach"
    },
    {
        "value": "North Miami",
        "label": "North Miami"
    },
    {
        "value": "Wellington",
        "label": "Wellington"
    },
    {
        "value": "North Port",
        "label": "North Port"
    },
    {
        "value": "Jupiter",
        "label": "Jupiter"
    },
    {
        "value": "Ocala",
        "label": "Ocala"
    },
    {
        "value": "Port Orange",
        "label": "Port Orange"
    },
    {
        "value": "Margate",
        "label": "Margate"
    },
    {
        "value": "Coconut Creek",
        "label": "Coconut Creek"
    },
    {
        "value": "Sanford",
        "label": "Sanford"
    },
    {
        "value": "Sarasota",
        "label": "Sarasota"
    },
    {
        "value": "Pensacola",
        "label": "Pensacola"
    },
    {
        "value": "Bradenton",
        "label": "Bradenton"
    },
    {
        "value": "Palm Beach Gardens",
        "label": "Palm Beach Gardens"
    },
    {
        "value": "Pinellas Park",
        "label": "Pinellas Park"
    },
    {
        "value": "Coral Gables",
        "label": "Coral Gables"
    },
    {
        "value": "Doral",
        "label": "Doral"
    },
    {
        "value": "Bonita Springs",
        "label": "Bonita Springs"
    },
    {
        "value": "Apopka",
        "label": "Apopka"
    },
    {
        "value": "Titusville",
        "label": "Titusville"
    },
    {
        "value": "North Miami Beach",
        "label": "North Miami Beach"
    },
    {
        "value": "Oakland Park",
        "label": "Oakland Park"
    },
    {
        "value": "Fort Pierce",
        "label": "Fort Pierce"
    },
    {
        "value": "North Lauderdale",
        "label": "North Lauderdale"
    },
    {
        "value": "Cutler Bay",
        "label": "Cutler Bay"
    },
    {
        "value": "Altamonte Springs",
        "label": "Altamonte Springs"
    },
    {
        "value": "St. Cloud",
        "label": "St. Cloud"
    },
    {
        "value": "Greenacres",
        "label": "Greenacres"
    },
    {
        "value": "Ormond Beach",
        "label": "Ormond Beach"
    },
    {
        "value": "Ocoee",
        "label": "Ocoee"
    },
    {
        "value": "Hallandale Beach",
        "label": "Hallandale Beach"
    },
    {
        "value": "Winter Garden",
        "label": "Winter Garden"
    },
    {
        "value": "Aventura",
        "label": "Aventura"
    },
    {
        "value": "Indianapolis",
        "label": "Indianapolis"
    },
    {
        "value": "Fort Wayne",
        "label": "Fort Wayne"
    },
    {
        "value": "Evansville",
        "label": "Evansville"
    },
    {
        "value": "South Bend",
        "label": "South Bend"
    },
    {
        "value": "Carmel",
        "label": "Carmel"
    },
    {
        "value": "Bloomington",
        "label": "Bloomington"
    },
    {
        "value": "Fishers",
        "label": "Fishers"
    },
    {
        "value": "Hammond",
        "label": "Hammond"
    },
    {
        "value": "Gary",
        "label": "Gary"
    },
    {
        "value": "Muncie",
        "label": "Muncie"
    },
    {
        "value": "Lafayette",
        "label": "Lafayette"
    },
    {
        "value": "Terre Haute",
        "label": "Terre Haute"
    },
    {
        "value": "Kokomo",
        "label": "Kokomo"
    },
    {
        "value": "Anderson",
        "label": "Anderson"
    },
    {
        "value": "Noblesville",
        "label": "Noblesville"
    },
    {
        "value": "Greenwood",
        "label": "Greenwood"
    },
    {
        "value": "Elkhart",
        "label": "Elkhart"
    },
    {
        "value": "Mishawaka",
        "label": "Mishawaka"
    },
    {
        "value": "Lawrence",
        "label": "Lawrence"
    },
    {
        "value": "Jeffersonville",
        "label": "Jeffersonville"
    },
    {
        "value": "Columbus",
        "label": "Columbus"
    },
    {
        "value": "Portage",
        "label": "Portage"
    },
    {
        "value": "Columbus",
        "label": "Columbus"
    },
    {
        "value": "Cleveland",
        "label": "Cleveland"
    },
    {
        "value": "Cincinnati",
        "label": "Cincinnati"
    },
    {
        "value": "Toledo",
        "label": "Toledo"
    },
    {
        "value": "Akron",
        "label": "Akron"
    },
    {
        "value": "Dayton",
        "label": "Dayton"
    },
    {
        "value": "Parma",
        "label": "Parma"
    },
    {
        "value": "Canton",
        "label": "Canton"
    },
    {
        "value": "Youngstown",
        "label": "Youngstown"
    },
    {
        "value": "Lorain",
        "label": "Lorain"
    },
    {
        "value": "Hamilton",
        "label": "Hamilton"
    },
    {
        "value": "Springfield",
        "label": "Springfield"
    },
    {
        "value": "Kettering",
        "label": "Kettering"
    },
    {
        "value": "Elyria",
        "label": "Elyria"
    },
    {
        "value": "Lakewood",
        "label": "Lakewood"
    },
    {
        "value": "Cuyahoga Falls",
        "label": "Cuyahoga Falls"
    },
    {
        "value": "Middletown",
        "label": "Middletown"
    },
    {
        "value": "Euclid",
        "label": "Euclid"
    },
    {
        "value": "Newark",
        "label": "Newark"
    },
    {
        "value": "Mansfield",
        "label": "Mansfield"
    },
    {
        "value": "Mentor",
        "label": "Mentor"
    },
    {
        "value": "Beavercreek",
        "label": "Beavercreek"
    },
    {
        "value": "Cleveland Heights",
        "label": "Cleveland Heights"
    },
    {
        "value": "Strongsville",
        "label": "Strongsville"
    },
    {
        "value": "Dublin",
        "label": "Dublin"
    },
    {
        "value": "Fairfield",
        "label": "Fairfield"
    },
    {
        "value": "Findlay",
        "label": "Findlay"
    },
    {
        "value": "Warren",
        "label": "Warren"
    },
    {
        "value": "Lancaster",
        "label": "Lancaster"
    },
    {
        "value": "Lima",
        "label": "Lima"
    },
    {
        "value": "Huber Heights",
        "label": "Huber Heights"
    },
    {
        "value": "Westerville",
        "label": "Westerville"
    },
    {
        "value": "Marion",
        "label": "Marion"
    },
    {
        "value": "Grove City",
        "label": "Grove City"
    },
    {
        "value": "Charlotte",
        "label": "Charlotte"
    },
    {
        "value": "Raleigh",
        "label": "Raleigh"
    },
    {
        "value": "Greensboro",
        "label": "Greensboro"
    },
    {
        "value": "Durham",
        "label": "Durham"
    },
    {
        "value": "Winston-Salem",
        "label": "Winston-Salem"
    },
    {
        "value": "Fayetteville",
        "label": "Fayetteville"
    },
    {
        "value": "Cary",
        "label": "Cary"
    },
    {
        "value": "Wilmington",
        "label": "Wilmington"
    },
    {
        "value": "High Point",
        "label": "High Point"
    },
    {
        "value": "Greenville",
        "label": "Greenville"
    },
    {
        "value": "Asheville",
        "label": "Asheville"
    },
    {
        "value": "Concord",
        "label": "Concord"
    },
    {
        "value": "Gastonia",
        "label": "Gastonia"
    },
    {
        "value": "Jacksonville",
        "label": "Jacksonville"
    },
    {
        "value": "Chapel Hill",
        "label": "Chapel Hill"
    },
    {
        "value": "Rocky Mount",
        "label": "Rocky Mount"
    },
    {
        "value": "Burlington",
        "label": "Burlington"
    },
    {
        "value": "Wilson",
        "label": "Wilson"
    },
    {
        "value": "Huntersville",
        "label": "Huntersville"
    },
    {
        "value": "Kannapolis",
        "label": "Kannapolis"
    },
    {
        "value": "Apex",
        "label": "Apex"
    },
    {
        "value": "Hickory",
        "label": "Hickory"
    },
    {
        "value": "Goldsboro",
        "label": "Goldsboro"
    },
    {
        "value": "Detroit",
        "label": "Detroit"
    },
    {
        "value": "Grand Rapids",
        "label": "Grand Rapids"
    },
    {
        "value": "Warren",
        "label": "Warren"
    },
    {
        "value": "Sterling Heights",
        "label": "Sterling Heights"
    },
    {
        "value": "Ann Arbor",
        "label": "Ann Arbor"
    },
    {
        "value": "Lansing",
        "label": "Lansing"
    },
    {
        "value": "Flint",
        "label": "Flint"
    },
    {
        "value": "Dearborn",
        "label": "Dearborn"
    },
    {
        "value": "Livonia",
        "label": "Livonia"
    },
    {
        "value": "Westland",
        "label": "Westland"
    },
    {
        "value": "Troy",
        "label": "Troy"
    },
    {
        "value": "Farmington Hills",
        "label": "Farmington Hills"
    },
    {
        "value": "Kalamazoo",
        "label": "Kalamazoo"
    },
    {
        "value": "Wyoming",
        "label": "Wyoming"
    },
    {
        "value": "Southfield",
        "label": "Southfield"
    },
    {
        "value": "Rochester Hills",
        "label": "Rochester Hills"
    },
    {
        "value": "Taylor",
        "label": "Taylor"
    },
    {
        "value": "Pontiac",
        "label": "Pontiac"
    },
    {
        "value": "St. Clair Shores",
        "label": "St. Clair Shores"
    },
    {
        "value": "Royal Oak",
        "label": "Royal Oak"
    },
    {
        "value": "Novi",
        "label": "Novi"
    },
    {
        "value": "Dearborn Heights",
        "label": "Dearborn Heights"
    },
    {
        "value": "Battle Creek",
        "label": "Battle Creek"
    },
    {
        "value": "Saginaw",
        "label": "Saginaw"
    },
    {
        "value": "Kentwood",
        "label": "Kentwood"
    },
    {
        "value": "East Lansing",
        "label": "East Lansing"
    },
    {
        "value": "Roseville",
        "label": "Roseville"
    },
    {
        "value": "Portage",
        "label": "Portage"
    },
    {
        "value": "Midland",
        "label": "Midland"
    },
    {
        "value": "Lincoln Park",
        "label": "Lincoln Park"
    },
    {
        "value": "Muskegon",
        "label": "Muskegon"
    },
    {
        "value": "Memphis",
        "label": "Memphis"
    },
    {
        "value": "Nashville-Davidson",
        "label": "Nashville-Davidson"
    },
    {
        "value": "Knoxville",
        "label": "Knoxville"
    },
    {
        "value": "Chattanooga",
        "label": "Chattanooga"
    },
    {
        "value": "Clarksville",
        "label": "Clarksville"
    },
    {
        "value": "Murfreesboro",
        "label": "Murfreesboro"
    },
    {
        "value": "Jackson",
        "label": "Jackson"
    },
    {
        "value": "Franklin",
        "label": "Franklin"
    },
    {
        "value": "Johnson City",
        "label": "Johnson City"
    },
    {
        "value": "Bartlett",
        "label": "Bartlett"
    },
    {
        "value": "Hendersonville",
        "label": "Hendersonville"
    },
    {
        "value": "Kingsport",
        "label": "Kingsport"
    },
    {
        "value": "Collierville",
        "label": "Collierville"
    },
    {
        "value": "Cleveland",
        "label": "Cleveland"
    },
    {
        "value": "Smyrna",
        "label": "Smyrna"
    },
    {
        "value": "Germantown",
        "label": "Germantown"
    },
    {
        "value": "Brentwood",
        "label": "Brentwood"
    },
    {
        "value": "Boston",
        "label": "Boston"
    },
    {
        "value": "Worcester",
        "label": "Worcester"
    },
    {
        "value": "Springfield",
        "label": "Springfield"
    },
    {
        "value": "Lowell",
        "label": "Lowell"
    },
    {
        "value": "Cambridge",
        "label": "Cambridge"
    },
    {
        "value": "New Bedford",
        "label": "New Bedford"
    },
    {
        "value": "Brockton",
        "label": "Brockton"
    },
    {
        "value": "Quincy",
        "label": "Quincy"
    },
    {
        "value": "Lynn",
        "label": "Lynn"
    },
    {
        "value": "Fall River",
        "label": "Fall River"
    },
    {
        "value": "Newton",
        "label": "Newton"
    },
    {
        "value": "Lawrence",
        "label": "Lawrence"
    },
    {
        "value": "Somerville",
        "label": "Somerville"
    },
    {
        "value": "Waltham",
        "label": "Waltham"
    },
    {
        "value": "Haverhill",
        "label": "Haverhill"
    },
    {
        "value": "Malden",
        "label": "Malden"
    },
    {
        "value": "Medford",
        "label": "Medford"
    },
    {
        "value": "Taunton",
        "label": "Taunton"
    },
    {
        "value": "Chicopee",
        "label": "Chicopee"
    },
    {
        "value": "Weymouth Town",
        "label": "Weymouth Town"
    },
    {
        "value": "Revere",
        "label": "Revere"
    },
    {
        "value": "Peabody",
        "label": "Peabody"
    },
    {
        "value": "Methuen",
        "label": "Methuen"
    },
    {
        "value": "Barnstable Town",
        "label": "Barnstable Town"
    },
    {
        "value": "Pittsfield",
        "label": "Pittsfield"
    },
    {
        "value": "Attleboro",
        "label": "Attleboro"
    },
    {
        "value": "Everett",
        "label": "Everett"
    },
    {
        "value": "Salem",
        "label": "Salem"
    },
    {
        "value": "Westfield",
        "label": "Westfield"
    },
    {
        "value": "Leominster",
        "label": "Leominster"
    },
    {
        "value": "Fitchburg",
        "label": "Fitchburg"
    },
    {
        "value": "Beverly",
        "label": "Beverly"
    },
    {
        "value": "Holyoke",
        "label": "Holyoke"
    },
    {
        "value": "Marlborough",
        "label": "Marlborough"
    },
    {
        "value": "Woburn",
        "label": "Woburn"
    },
    {
        "value": "Chelsea",
        "label": "Chelsea"
    },
    {
        "value": "Seattle",
        "label": "Seattle"
    },
    {
        "value": "Spokane",
        "label": "Spokane"
    },
    {
        "value": "Tacoma",
        "label": "Tacoma"
    },
    {
        "value": "Vancouver",
        "label": "Vancouver"
    },
    {
        "value": "Bellevue",
        "label": "Bellevue"
    },
    {
        "value": "Kent",
        "label": "Kent"
    },
    {
        "value": "Everett",
        "label": "Everett"
    },
    {
        "value": "Renton",
        "label": "Renton"
    },
    {
        "value": "Yakima",
        "label": "Yakima"
    },
    {
        "value": "Federal Way",
        "label": "Federal Way"
    },
    {
        "value": "Spokane Valley",
        "label": "Spokane Valley"
    },
    {
        "value": "Bellingham",
        "label": "Bellingham"
    },
    {
        "value": "Kennewick",
        "label": "Kennewick"
    },
    {
        "value": "Auburn",
        "label": "Auburn"
    },
    {
        "value": "Pasco",
        "label": "Pasco"
    },
    {
        "value": "Marysville",
        "label": "Marysville"
    },
    {
        "value": "Lakewood",
        "label": "Lakewood"
    },
    {
        "value": "Redmond",
        "label": "Redmond"
    },
    {
        "value": "Shoreline",
        "label": "Shoreline"
    },
    {
        "value": "Richland",
        "label": "Richland"
    },
    {
        "value": "Kirkland",
        "label": "Kirkland"
    },
    {
        "value": "Burien",
        "label": "Burien"
    },
    {
        "value": "Sammamish",
        "label": "Sammamish"
    },
    {
        "value": "Olympia",
        "label": "Olympia"
    },
    {
        "value": "Lacey",
        "label": "Lacey"
    },
    {
        "value": "Edmonds",
        "label": "Edmonds"
    },
    {
        "value": "Bremerton",
        "label": "Bremerton"
    },
    {
        "value": "Puyallup",
        "label": "Puyallup"
    },
    {
        "value": "Denver",
        "label": "Denver"
    },
    {
        "value": "Colorado Springs",
        "label": "Colorado Springs"
    },
    {
        "value": "Aurora",
        "label": "Aurora"
    },
    {
        "value": "Fort Collins",
        "label": "Fort Collins"
    },
    {
        "value": "Lakewood",
        "label": "Lakewood"
    },
    {
        "value": "Thornton",
        "label": "Thornton"
    },
    {
        "value": "Arvada",
        "label": "Arvada"
    },
    {
        "value": "Westminster",
        "label": "Westminster"
    },
    {
        "value": "Pueblo",
        "label": "Pueblo"
    },
    {
        "value": "Centennial",
        "label": "Centennial"
    },
    {
        "value": "Boulder",
        "label": "Boulder"
    },
    {
        "value": "Greeley",
        "label": "Greeley"
    },
    {
        "value": "Longmont",
        "label": "Longmont"
    },
    {
        "value": "Loveland",
        "label": "Loveland"
    },
    {
        "value": "Grand Junction",
        "label": "Grand Junction"
    },
    {
        "value": "Broomfield",
        "label": "Broomfield"
    },
    {
        "value": "Castle Rock",
        "label": "Castle Rock"
    },
    {
        "value": "Commerce City",
        "label": "Commerce City"
    },
    {
        "value": "Parker",
        "label": "Parker"
    },
    {
        "value": "Littleton",
        "label": "Littleton"
    },
    {
        "value": "Northglenn",
        "label": "Northglenn"
    },
    {
        "value": "Washington",
        "label": "Washington"
    },
    {
        "value": "Baltimore",
        "label": "Baltimore"
    },
    {
        "value": "Frederick",
        "label": "Frederick"
    },
    {
        "value": "Rockville",
        "label": "Rockville"
    },
    {
        "value": "Gaithersburg",
        "label": "Gaithersburg"
    },
    {
        "value": "Bowie",
        "label": "Bowie"
    },
    {
        "value": "Hagerstown",
        "label": "Hagerstown"
    },
    {
        "value": "Annapolis",
        "label": "Annapolis"
    },
    {
        "value": "Louisville/Jefferson County",
        "label": "Louisville/Jefferson County"
    },
    {
        "value": "Lexington-Fayette",
        "label": "Lexington-Fayette"
    },
    {
        "value": "Bowling Green",
        "label": "Bowling Green"
    },
    {
        "value": "Owensboro",
        "label": "Owensboro"
    },
    {
        "value": "Covington",
        "label": "Covington"
    },
    {
        "value": "Portland",
        "label": "Portland"
    },
    {
        "value": "Eugene",
        "label": "Eugene"
    },
    {
        "value": "Salem",
        "label": "Salem"
    },
    {
        "value": "Gresham",
        "label": "Gresham"
    },
    {
        "value": "Hillsboro",
        "label": "Hillsboro"
    },
    {
        "value": "Beaverton",
        "label": "Beaverton"
    },
    {
        "value": "Bend",
        "label": "Bend"
    },
    {
        "value": "Medford",
        "label": "Medford"
    },
    {
        "value": "Springfield",
        "label": "Springfield"
    },
    {
        "value": "Corvallis",
        "label": "Corvallis"
    },
    {
        "value": "Albany",
        "label": "Albany"
    },
    {
        "value": "Tigard",
        "label": "Tigard"
    },
    {
        "value": "Lake Oswego",
        "label": "Lake Oswego"
    },
    {
        "value": "Keizer",
        "label": "Keizer"
    },
    {
        "value": "Oklahoma City",
        "label": "Oklahoma City"
    },
    {
        "value": "Tulsa",
        "label": "Tulsa"
    },
    {
        "value": "Norman",
        "label": "Norman"
    },
    {
        "value": "Broken Arrow",
        "label": "Broken Arrow"
    },
    {
        "value": "Lawton",
        "label": "Lawton"
    },
    {
        "value": "Edmond",
        "label": "Edmond"
    },
    {
        "value": "Moore",
        "label": "Moore"
    },
    {
        "value": "Midwest City",
        "label": "Midwest City"
    },
    {
        "value": "Enid",
        "label": "Enid"
    },
    {
        "value": "Stillwater",
        "label": "Stillwater"
    },
    {
        "value": "Muskogee",
        "label": "Muskogee"
    },
    {
        "value": "Milwaukee",
        "label": "Milwaukee"
    },
    {
        "value": "Madison",
        "label": "Madison"
    },
    {
        "value": "Green Bay",
        "label": "Green Bay"
    },
    {
        "value": "Kenosha",
        "label": "Kenosha"
    },
    {
        "value": "Racine",
        "label": "Racine"
    },
    {
        "value": "Appleton",
        "label": "Appleton"
    },
    {
        "value": "Waukesha",
        "label": "Waukesha"
    },
    {
        "value": "Eau Claire",
        "label": "Eau Claire"
    },
    {
        "value": "Oshkosh",
        "label": "Oshkosh"
    },
    {
        "value": "Janesville",
        "label": "Janesville"
    },
    {
        "value": "West Allis",
        "label": "West Allis"
    },
    {
        "value": "La Crosse",
        "label": "La Crosse"
    },
    {
        "value": "Sheboygan",
        "label": "Sheboygan"
    },
    {
        "value": "Wauwatosa",
        "label": "Wauwatosa"
    },
    {
        "value": "Fond du Lac",
        "label": "Fond du Lac"
    },
    {
        "value": "New Berlin",
        "label": "New Berlin"
    },
    {
        "value": "Wausau",
        "label": "Wausau"
    },
    {
        "value": "Brookfield",
        "label": "Brookfield"
    },
    {
        "value": "Greenfield",
        "label": "Greenfield"
    },
    {
        "value": "Beloit",
        "label": "Beloit"
    },
    {
        "value": "Las Vegas",
        "label": "Las Vegas"
    },
    {
        "value": "Henderson",
        "label": "Henderson"
    },
    {
        "value": "Reno",
        "label": "Reno"
    },
    {
        "value": "North Las Vegas",
        "label": "North Las Vegas"
    },
    {
        "value": "Sparks",
        "label": "Sparks"
    },
    {
        "value": "Carson City",
        "label": "Carson City"
    },
    {
        "value": "Albuquerque",
        "label": "Albuquerque"
    },
    {
        "value": "Las Cruces",
        "label": "Las Cruces"
    },
    {
        "value": "Rio Rancho",
        "label": "Rio Rancho"
    },
    {
        "value": "Santa Fe",
        "label": "Santa Fe"
    },
    {
        "value": "Roswell",
        "label": "Roswell"
    },
    {
        "value": "Farmington",
        "label": "Farmington"
    },
    {
        "value": "Clovis",
        "label": "Clovis"
    },
    {
        "value": "Kansas City",
        "label": "Kansas City"
    },
    {
        "value": "St. Louis",
        "label": "St. Louis"
    },
    {
        "value": "Springfield",
        "label": "Springfield"
    },
    {
        "value": "Independence",
        "label": "Independence"
    },
    {
        "value": "Columbia",
        "label": "Columbia"
    },
    {
        "value": "Lee's Summit",
        "label": "Lee's Summit"
    },
    {
        "value": "O'Fallon",
        "label": "O'Fallon"
    },
    {
        "value": "St. Joseph",
        "label": "St. Joseph"
    },
    {
        "value": "St. Charles",
        "label": "St. Charles"
    },
    {
        "value": "St. Peters",
        "label": "St. Peters"
    },
    {
        "value": "Blue Springs",
        "label": "Blue Springs"
    },
    {
        "value": "Florissant",
        "label": "Florissant"
    },
    {
        "value": "Joplin",
        "label": "Joplin"
    },
    {
        "value": "Chesterfield",
        "label": "Chesterfield"
    },
    {
        "value": "Jefferson City",
        "label": "Jefferson City"
    },
    {
        "value": "Cape Girardeau",
        "label": "Cape Girardeau"
    },
    {
        "value": "Virginia Beach",
        "label": "Virginia Beach"
    },
    {
        "value": "Norfolk",
        "label": "Norfolk"
    },
    {
        "value": "Chesapeake",
        "label": "Chesapeake"
    },
    {
        "value": "Richmond",
        "label": "Richmond"
    },
    {
        "value": "Newport News",
        "label": "Newport News"
    },
    {
        "value": "Alexandria",
        "label": "Alexandria"
    },
    {
        "value": "Hampton",
        "label": "Hampton"
    },
    {
        "value": "Roanoke",
        "label": "Roanoke"
    },
    {
        "value": "Portsmouth",
        "label": "Portsmouth"
    },
    {
        "value": "Suffolk",
        "label": "Suffolk"
    },
    {
        "value": "Lynchburg",
        "label": "Lynchburg"
    },
    {
        "value": "Harrisonburg",
        "label": "Harrisonburg"
    },
    {
        "value": "Leesburg",
        "label": "Leesburg"
    },
    {
        "value": "Charlottesville",
        "label": "Charlottesville"
    },
    {
        "value": "Danville",
        "label": "Danville"
    },
    {
        "value": "Blacksburg",
        "label": "Blacksburg"
    },
    {
        "value": "Manassas",
        "label": "Manassas"
    },
    {
        "value": "Atlanta",
        "label": "Atlanta"
    },
    {
        "value": "Columbus",
        "label": "Columbus"
    },
    {
        "value": "Augusta-Richmond County",
        "label": "Augusta-Richmond County"
    },
    {
        "value": "Savannah",
        "label": "Savannah"
    },
    {
        "value": "Athens-Clarke County",
        "label": "Athens-Clarke County"
    },
    {
        "value": "Sandy Springs",
        "label": "Sandy Springs"
    },
    {
        "value": "Roswell",
        "label": "Roswell"
    },
    {
        "value": "Macon",
        "label": "Macon"
    },
    {
        "value": "Johns Creek",
        "label": "Johns Creek"
    },
    {
        "value": "Albany",
        "label": "Albany"
    },
    {
        "value": "Warner Robins",
        "label": "Warner Robins"
    },
    {
        "value": "Alpharetta",
        "label": "Alpharetta"
    },
    {
        "value": "Marietta",
        "label": "Marietta"
    },
    {
        "value": "Valdosta",
        "label": "Valdosta"
    },
    {
        "value": "Smyrna",
        "label": "Smyrna"
    },
    {
        "value": "Dunwoody",
        "label": "Dunwoody"
    },
    {
        "value": "Omaha",
        "label": "Omaha"
    },
    {
        "value": "Lincoln",
        "label": "Lincoln"
    },
    {
        "value": "Bellevue",
        "label": "Bellevue"
    },
    {
        "value": "Grand Island",
        "label": "Grand Island"
    },
    {
        "value": "Minneapolis",
        "label": "Minneapolis"
    },
    {
        "value": "St. Paul",
        "label": "St. Paul"
    },
    {
        "value": "Rochester",
        "label": "Rochester"
    },
    {
        "value": "Duluth",
        "label": "Duluth"
    },
    {
        "value": "Bloomington",
        "label": "Bloomington"
    },
    {
        "value": "Brooklyn Park",
        "label": "Brooklyn Park"
    },
    {
        "value": "Plymouth",
        "label": "Plymouth"
    },
    {
        "value": "St. Cloud",
        "label": "St. Cloud"
    },
    {
        "value": "Eagan",
        "label": "Eagan"
    },
    {
        "value": "Woodbury",
        "label": "Woodbury"
    },
    {
        "value": "Maple Grove",
        "label": "Maple Grove"
    },
    {
        "value": "Eden Prairie",
        "label": "Eden Prairie"
    },
    {
        "value": "Coon Rapids",
        "label": "Coon Rapids"
    },
    {
        "value": "Burnsville",
        "label": "Burnsville"
    },
    {
        "value": "Blaine",
        "label": "Blaine"
    },
    {
        "value": "Lakeville",
        "label": "Lakeville"
    },
    {
        "value": "Minnetonka",
        "label": "Minnetonka"
    },
    {
        "value": "Apple Valley",
        "label": "Apple Valley"
    },
    {
        "value": "Edina",
        "label": "Edina"
    },
    {
        "value": "St. Louis Park",
        "label": "St. Louis Park"
    },
    {
        "value": "Mankato",
        "label": "Mankato"
    },
    {
        "value": "Maplewood",
        "label": "Maplewood"
    },
    {
        "value": "Moorhead",
        "label": "Moorhead"
    },
    {
        "value": "Shakopee",
        "label": "Shakopee"
    },
    {
        "value": "Wichita",
        "label": "Wichita"
    },
    {
        "value": "Overland Park",
        "label": "Overland Park"
    },
    {
        "value": "Kansas City",
        "label": "Kansas City"
    },
    {
        "value": "Olathe",
        "label": "Olathe"
    },
    {
        "value": "Topeka",
        "label": "Topeka"
    },
    {
        "value": "Lawrence",
        "label": "Lawrence"
    },
    {
        "value": "Shawnee",
        "label": "Shawnee"
    },
    {
        "value": "Manhattan",
        "label": "Manhattan"
    },
    {
        "value": "Lenexa",
        "label": "Lenexa"
    },
    {
        "value": "Salina",
        "label": "Salina"
    },
    {
        "value": "Hutchinson",
        "label": "Hutchinson"
    },
    {
        "value": "New Orleans",
        "label": "New Orleans"
    },
    {
        "value": "Baton Rouge",
        "label": "Baton Rouge"
    },
    {
        "value": "Shreveport",
        "label": "Shreveport"
    },
    {
        "value": "Lafayette",
        "label": "Lafayette"
    },
    {
        "value": "Lake Charles",
        "label": "Lake Charles"
    },
    {
        "value": "Kenner",
        "label": "Kenner"
    },
    {
        "value": "Bossier City",
        "label": "Bossier City"
    },
    {
        "value": "Monroe",
        "label": "Monroe"
    },
    {
        "value": "Alexandria",
        "label": "Alexandria"
    },
    {
        "value": "Honolulu",
        "label": "Honolulu"
    },
    {
        "value": "Anchorage",
        "label": "Anchorage"
    },
    {
        "value": "Newark",
        "label": "Newark"
    },
    {
        "value": "Jersey City",
        "label": "Jersey City"
    },
    {
        "value": "Paterson",
        "label": "Paterson"
    },
    {
        "value": "Elizabeth",
        "label": "Elizabeth"
    },
    {
        "value": "Clifton",
        "label": "Clifton"
    },
    {
        "value": "Trenton",
        "label": "Trenton"
    },
    {
        "value": "Camden",
        "label": "Camden"
    },
    {
        "value": "Passaic",
        "label": "Passaic"
    },
    {
        "value": "Union City",
        "label": "Union City"
    },
    {
        "value": "Bayonne",
        "label": "Bayonne"
    },
    {
        "value": "East Orange",
        "label": "East Orange"
    },
    {
        "value": "Vineland",
        "label": "Vineland"
    },
    {
        "value": "New Brunswick",
        "label": "New Brunswick"
    },
    {
        "value": "Hoboken",
        "label": "Hoboken"
    },
    {
        "value": "Perth Amboy",
        "label": "Perth Amboy"
    },
    {
        "value": "West New York",
        "label": "West New York"
    },
    {
        "value": "Plainfield",
        "label": "Plainfield"
    },
    {
        "value": "Hackensack",
        "label": "Hackensack"
    },
    {
        "value": "Sayreville",
        "label": "Sayreville"
    },
    {
        "value": "Kearny",
        "label": "Kearny"
    },
    {
        "value": "Linden",
        "label": "Linden"
    },
    {
        "value": "Atlantic City",
        "label": "Atlantic City"
    },
    {
        "value": "Boise City",
        "label": "Boise City"
    },
    {
        "value": "Nampa",
        "label": "Nampa"
    },
    {
        "value": "Meridian",
        "label": "Meridian"
    },
    {
        "value": "Idaho Falls",
        "label": "Idaho Falls"
    },
    {
        "value": "Pocatello",
        "label": "Pocatello"
    },
    {
        "value": "Caldwell",
        "label": "Caldwell"
    },
    {
        "value": "Coeur d'Alene",
        "label": "Coeur d'Alene"
    },
    {
        "value": "Twin Falls",
        "label": "Twin Falls"
    },
    {
        "value": "Birmingham",
        "label": "Birmingham"
    },
    {
        "value": "Montgomery",
        "label": "Montgomery"
    },
    {
        "value": "Mobile",
        "label": "Mobile"
    },
    {
        "value": "Huntsville",
        "label": "Huntsville"
    },
    {
        "value": "Tuscaloosa",
        "label": "Tuscaloosa"
    },
    {
        "value": "Hoover",
        "label": "Hoover"
    },
    {
        "value": "Dothan",
        "label": "Dothan"
    },
    {
        "value": "Auburn",
        "label": "Auburn"
    },
    {
        "value": "Decatur",
        "label": "Decatur"
    },
    {
        "value": "Madison",
        "label": "Madison"
    },
    {
        "value": "Florence",
        "label": "Florence"
    },
    {
        "value": "Gadsden",
        "label": "Gadsden"
    },
    {
        "value": "Des Moines",
        "label": "Des Moines"
    },
    {
        "value": "Cedar Rapids",
        "label": "Cedar Rapids"
    },
    {
        "value": "Davenport",
        "label": "Davenport"
    },
    {
        "value": "Sioux City",
        "label": "Sioux City"
    },
    {
        "value": "Iowa City",
        "label": "Iowa City"
    },
    {
        "value": "Waterloo",
        "label": "Waterloo"
    },
    {
        "value": "Council Bluffs",
        "label": "Council Bluffs"
    },
    {
        "value": "Ames",
        "label": "Ames"
    },
    {
        "value": "West Des Moines",
        "label": "West Des Moines"
    },
    {
        "value": "Dubuque",
        "label": "Dubuque"
    },
    {
        "value": "Ankeny",
        "label": "Ankeny"
    },
    {
        "value": "Urbandale",
        "label": "Urbandale"
    },
    {
        "value": "Cedar Falls",
        "label": "Cedar Falls"
    },
    {
        "value": "Little Rock",
        "label": "Little Rock"
    },
    {
        "value": "Fort Smith",
        "label": "Fort Smith"
    },
    {
        "value": "Fayetteville",
        "label": "Fayetteville"
    },
    {
        "value": "Springdale",
        "label": "Springdale"
    },
    {
        "value": "Jonesboro",
        "label": "Jonesboro"
    },
    {
        "value": "North Little Rock",
        "label": "North Little Rock"
    },
    {
        "value": "Conway",
        "label": "Conway"
    },
    {
        "value": "Rogers",
        "label": "Rogers"
    },
    {
        "value": "Pine Bluff",
        "label": "Pine Bluff"
    },
    {
        "value": "Bentonville",
        "label": "Bentonville"
    },
    {
        "value": "Salt Lake City",
        "label": "Salt Lake City"
    },
    {
        "value": "West Valley City",
        "label": "West Valley City"
    },
    {
        "value": "Provo",
        "label": "Provo"
    },
    {
        "value": "West Jordan",
        "label": "West Jordan"
    },
    {
        "value": "Orem",
        "label": "Orem"
    },
    {
        "value": "Sandy",
        "label": "Sandy"
    },
    {
        "value": "Ogden",
        "label": "Ogden"
    },
    {
        "value": "St. George",
        "label": "St. George"
    },
    {
        "value": "Layton",
        "label": "Layton"
    },
    {
        "value": "Taylorsville",
        "label": "Taylorsville"
    },
    {
        "value": "South Jordan",
        "label": "South Jordan"
    },
    {
        "value": "Lehi",
        "label": "Lehi"
    },
    {
        "value": "Logan",
        "label": "Logan"
    },
    {
        "value": "Murray",
        "label": "Murray"
    },
    {
        "value": "Draper",
        "label": "Draper"
    },
    {
        "value": "Bountiful",
        "label": "Bountiful"
    },
    {
        "value": "Riverton",
        "label": "Riverton"
    },
    {
        "value": "Roy",
        "label": "Roy"
    },
    {
        "value": "Providence",
        "label": "Providence"
    },
    {
        "value": "Warwick",
        "label": "Warwick"
    },
    {
        "value": "Cranston",
        "label": "Cranston"
    },
    {
        "value": "Pawtucket",
        "label": "Pawtucket"
    },
    {
        "value": "East Providence",
        "label": "East Providence"
    },
    {
        "value": "Woonsocket",
        "label": "Woonsocket"
    },
    {
        "value": "Jackson",
        "label": "Jackson"
    },
    {
        "value": "Gulfport",
        "label": "Gulfport"
    },
    {
        "value": "Southaven",
        "label": "Southaven"
    },
    {
        "value": "Hattiesburg",
        "label": "Hattiesburg"
    },
    {
        "value": "Biloxi",
        "label": "Biloxi"
    },
    {
        "value": "Meridian",
        "label": "Meridian"
    },
    {
        "value": "Sioux Falls",
        "label": "Sioux Falls"
    },
    {
        "value": "Rapid City",
        "label": "Rapid City"
    },
    {
        "value": "Bridgeport",
        "label": "Bridgeport"
    },
    {
        "value": "New Haven",
        "label": "New Haven"
    },
    {
        "value": "Stamford",
        "label": "Stamford"
    },
    {
        "value": "Hartford",
        "label": "Hartford"
    },
    {
        "value": "Waterbury",
        "label": "Waterbury"
    },
    {
        "value": "Norwalk",
        "label": "Norwalk"
    },
    {
        "value": "Danbury",
        "label": "Danbury"
    },
    {
        "value": "New Britain",
        "label": "New Britain"
    },
    {
        "value": "Meriden",
        "label": "Meriden"
    },
    {
        "value": "Bristol",
        "label": "Bristol"
    },
    {
        "value": "West Haven",
        "label": "West Haven"
    },
    {
        "value": "Milford",
        "label": "Milford"
    },
    {
        "value": "Middletown",
        "label": "Middletown"
    },
    {
        "value": "Norwich",
        "label": "Norwich"
    },
    {
        "value": "Shelton",
        "label": "Shelton"
    },
    {
        "value": "Columbia",
        "label": "Columbia"
    },
    {
        "value": "Charleston",
        "label": "Charleston"
    },
    {
        "value": "North Charleston",
        "label": "North Charleston"
    },
    {
        "value": "Mount Pleasant",
        "label": "Mount Pleasant"
    },
    {
        "value": "Rock Hill",
        "label": "Rock Hill"
    },
    {
        "value": "Greenville",
        "label": "Greenville"
    },
    {
        "value": "Summerville",
        "label": "Summerville"
    },
    {
        "value": "Sumter",
        "label": "Sumter"
    },
    {
        "value": "Goose Creek",
        "label": "Goose Creek"
    },
    {
        "value": "Hilton Head Island",
        "label": "Hilton Head Island"
    },
    {
        "value": "Florence",
        "label": "Florence"
    },
    {
        "value": "Spartanburg",
        "label": "Spartanburg"
    },
    {
        "value": "Manchester",
        "label": "Manchester"
    },
    {
        "value": "Nashua",
        "label": "Nashua"
    },
    {
        "value": "Concord",
        "label": "Concord"
    },
    {
        "value": "Fargo",
        "label": "Fargo"
    },
    {
        "value": "Bismarck",
        "label": "Bismarck"
    },
    {
        "value": "Grand Forks",
        "label": "Grand Forks"
    },
    {
        "value": "Minot",
        "label": "Minot"
    },
    {
        "value": "Billings",
        "label": "Billings"
    },
    {
        "value": "Missoula",
        "label": "Missoula"
    },
    {
        "value": "Great Falls",
        "label": "Great Falls"
    },
    {
        "value": "Bozeman",
        "label": "Bozeman"
    },
    {
        "value": "Wilmington",
        "label": "Wilmington"
    },
    {
        "value": "Dover",
        "label": "Dover"
    },
    {
        "value": "Portland",
        "label": "Portland"
    },
    {
        "value": "Cheyenne",
        "label": "Cheyenne"
    },
    {
        "value": "Casper",
        "label": "Casper"
    },
    {
        "value": "Charleston",
        "label": "Charleston"
    },
    {
        "value": "Huntington",
        "label": "Huntington"
    },
    {
        "value": "Burlington",
        "label": "Burlington"
    }
]
