import { setFeaturedJobs, setJobs, setSingleJob } from '../reducers/jobReducer';
import { fetchFilteredJobs, categoryFilteredJobs } from '../../assets/database/filteredJobs';
import { fetchRandomJobs } from '../../assets/database/randomJobs';
import img from '../../assets/database/fulllogo_transparent_nobuffer 4.png'

import { setLoading } from '../reducers/loadingReducer';
import { instance } from '../../utils/constants';

export const fetchJobs = (query) => async (dispatch) => {
    // Set loading to true
    dispatch(setLoading(true));
    try {
        const jobs = await fetchFilteredJobs(query); // fetchFilteredJobs already returns response.data
        console.log(jobs); // For debugging
        dispatch(setJobs(jobs)); // Using the action created by createSlice
    } catch (error) {
        console.error("Error fetching jobs:", error);
    } finally {
        // Set loading to false after fetching is done
        dispatch(setLoading(false));
    }
};

export const findJobs = ({ query, setPrevQuery }) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await instance.get(`/jobs?${query}`)
        const jobsWithImage = response.data.jobs.map((job) => ({ ...job, img }));
        if (query.includes('isFeatured=true')) {
            dispatch(setFeaturedJobs({ jobs: jobsWithImage, total: response.data.total }))
        } else {
            dispatch(setJobs({ jobs: jobsWithImage, total: response.data.total }))
        }
        if (setPrevQuery) {
            setPrevQuery(query)
        }
    } catch (err) {
        console.log('find jobs error: ', err);
    } finally {
        dispatch(setLoading(false))
    }
}

export const findSingleJobs = (jobID) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await instance.get(`/job/${jobID}`)
        console.log('rrrrrrrrrrrrrr: ', response.data)
        dispatch(setSingleJob(response.data.job))
    } catch (err) {
        console.log('find jobs error: ', err);
    } finally {
        dispatch(setLoading(false))
    }
}


export const RandomJobsAction = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const jobs = await fetchRandomJobs(); // fetchFilteredJobs already returns response.data
        console.log(jobs); // For debugging
        dispatch(setJobs(jobs)); // Using the action created by createSlice
    } catch (error) {
        console.error("Error fetching jobs:", error);
    } finally {
        // Set loading to false after fetching is done
        dispatch(setLoading(false));
    }
}

export const fetchFeaturedJobsAction = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await instance.get(`/jobs/getTop10Featured`);
        const jobsWithImage = response.data.map((job) => ({ ...job, img }));
        dispatch(setFeaturedJobs(jobsWithImage)); // Dispatch the jobs to Redux
    } catch (error) {
        console.error('Error fetching featured jobs:', error);
    } finally {
        dispatch(setLoading(false));
    }
};
export const categoryFilteredJobsAction = (query) => async (dispatch) => {
    // Set loading to true
    dispatch(setLoading(true));
    try {
        const jobs = await categoryFilteredJobs(query);
        console.log(jobs); // For debugging
        dispatch(setJobs(jobs)); // Using the action created by createSlice
    } catch (error) {
        console.error("Error fetching jobs:", error);
    } finally {
        // Set loading to false after fetching is done
        dispatch(setLoading(false));
    }
};