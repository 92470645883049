import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import RangeOne from "../range-slider";
// import RangeTwo from "../range-slider/RangleTwo";
// import SelectLocation from "../Banner/dropdown";
// import Dropdown from "react-dropdown";
// import { fetchJobs } from '../../redux/actions/jobActions';
// import { jobTitles } from '../../assets/database/jobTitles';
import { jobCategoryOptions, jobTypeOptions, postedTimeOptions } from '../../assets/database/options';
import { CustomDropdown } from '../dropdown/CustomDropdown';
import { usCities } from '../../assets/database/us-cities';
import { setSearch } from '../../redux/reducers/searchReducer';


// const select2 = [
//   { value: "s1", label: "On-site" },
//   { value: "s2", label: "Remote" },
//   { value: "s3", label: "Freelancer" },
// ];

// const select5 = [
//   { value: "s1", label: "All Seniority Levels" },
//   { value: "s2", label: "Junior" },
//   { value: "s3", label: "Senior" },
// ];
// const select6 = [
//   { value: "s1", label: "Company" },
//   { value: "s2", label: "Website" },
// ];

function Sidebar({ handleFilterChange }) {

  const dispatch = useDispatch()

  const [error, setError] = useState(false);
  const loading = useSelector((state) => state.loading);
  const filters = useSelector((state) => state.filters);
  const search = useSelector((state) => state.search);


  const isInitialRender = useRef(true);

  const [filterOptions, setFilterOptions] = useState({
    keyword: filters.keyword,
    postedTime: '',
    jobType: '',
    location: ''
  })

  const handleSearch = (value) => {
    dispatch(setSearch(value))
  }

  const changeFilter = ({ name, value }) => {
    setFilterOptions(prev => ({ ...prev, currentPage: 1, [name]: value }))
  }

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the first render
      isInitialRender.current = false;
      return;
    }

    const getData = setTimeout(() => {
      handleFilterChange({ name: 'currentPage', value: 1 })
    }, 1000)

    return () => clearTimeout(getData)
  }, [search])


  const handleSubmit = async (e) => {
    // e.preventDefault();
    for (const key in filterOptions) {
      handleFilterChange({ name: key, value: filterOptions[key] })
    }

  };

  return (
    <div className="widget-filter st2 style-scroll po-sticky">
      {/* <form > */}
      <div className="group-form">
        <label className="title">Custom Search</label>
        <div className="group-input search-ip">
          <button type="submit">
            <i className="icon-search"></i>
          </button>
          <input
            type="text"
            placeholder="Job title or company"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            required
          />
        </div>
      </div>

      <div className="group-form">
        <label className="title">Location</label>
        <CustomDropdown
          isClearable={true}
          options={usCities}
          onChange={option =>
            option ? changeFilter({ name: 'location', value: option.value }) : changeFilter({ name: 'location', value: '' })}
        />
      </div>

      {/* Dropdown for Job Categories */}
      <div className="group-form">
        <label className="title">Job Category</label>
        <CustomDropdown
          isClearable={true}
          isSearchable={false}
          options={jobCategoryOptions}
          // value={filterOptions.keyword}
          value={jobCategoryOptions.find(item => item.value === filterOptions.keyword)}
          onChange={option => {
            option ? changeFilter({ name: 'keyword', value: option.value }) : changeFilter({ name: 'keyword', value: '' })
          }}
        />
      </div>

      {/* Dropdown for Job Titles based on Selected Category */}
      {/* <div className="group-form">
        <label className="title">Job Title</label>
        <Dropdown
          options={titlesInSelectedCategory?.map(title => ({ value: title.value, label: title.value }))}
          className="react-dropdown select2"

          onChange={(option) => setJobTitle(option.value)}
          value={jobTitle ? { value: jobTitle, label: jobTitle } : titlesInSelectedCategory[0]} // Ensure the selected job title is displayed correctly

        />
      </div> */}


      {/* job types filtter */}
      <div className="group-form">
        <label className="title">Job Types</label>
        <CustomDropdown
          isClearable={true}
          isSearchable={false}
          options={jobTypeOptions}
          onChange={option =>
            option ? changeFilter({ name: 'jobType', value: option.value }) : changeFilter({ name: 'jobType', value: '' })}
        />
      </div>

      {/* <RangeTwo title="Salary:" />

      <RangeOne miles="Miles" title="Radius:" /> */}

      <div className="group-form">
        <label className="title">select period</label>
        <CustomDropdown
          isClearable={true}
          isSearchable={false}
          options={postedTimeOptions}
          onChange={option =>
            option ? changeFilter({ name: 'postedTime', value: option.value }) : changeFilter({ name: 'postedTime', value: '' })}
        />
      </div>

      {/* <div className="group-form">
        <label className="title">All Seniority Levels</label>
        <div className="group-input">
          <Dropdown
            options={select5}
            className="react-dropdown select2"
            onChange={(option) => setSeniorityLevel(option.value)}
            value={select5.find(item => item.value === seniorityLevel)}
          />
        </div>
      </div> */}

      {/* <div className="group-form">
        <label className="title">Company</label>
        <div className="group-input">
          <Dropdown
            options={select6}
            className="react-dropdown select2"
            onChange={(option) => setCompany(option.value)}
            value={select6.find(item => item.value === company)}
          />
        </div>
      </div> */}

      <button type='button'
        onClick={handleSubmit}
      >
        {loading ? 'Loading...' : 'Find Jobs'}
      </button>
      {/* </form> */}
      {error && <p className="error">There was an error fetching the jobs. Please try again.</p>}
    </div>
  );
}

export default Sidebar;
