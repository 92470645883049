import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    resultsPerPage: 12,
    currentPage: 1,
    sortOrder: -1,
    prevQuery: ''
}

const filterSlice = createSlice({
    name: 'filters',
    initialState, // Initially, we are not loading
    reducers: {
        setFilters: (state, action) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }

        },
        resetFilters: () => initialState,
        setPrevQuery: (state, action) => ({ ...state, prevQuery: action.payload })
    },
});

// Export the action creator
export const { setFilters, resetFilters } = filterSlice.actions;
// Export the reducer
export default filterSlice.reducer;
