import React from "react";
import Select, { components } from "react-select";

// Sample options
const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
];

// Custom DropdownIndicator with arrow functionality
const CustomDropdownIndicator = (props) => {
    const { menuIsOpen } = props.selectProps;
    return (
        <components.DropdownIndicator {...props}>
            <div
                className="custom-dropdown-arrow-wrapper"
                style={{
                    transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
            >
                <span className="Dropdown-arrow"></span>
            </div>
        </components.DropdownIndicator>
    );
};

// Custom styles to reduce height
const customStyles = {
    control: (base) => ({
        ...base,
        minHeight: "32px", // Adjust dropdown box height
        height: "50px",
    }),
    valueContainer: (base) => ({
        ...base,
        padding: "0 8px", // Adjust inner padding
        display: "flex",
        alignItems: "center", // Center the content vertically
    }),
    input: (base) => ({
        ...base,
        margin: 0, // Remove unnecessary margins
        padding: 0, // Ensure no extra padding
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: "14px", // Adjust font size
        lineHeight: "1", // Ensure placeholder fits within height
        margin: 0,
    }),
    indicatorsContainer: (base) => ({
        ...base,
        height: "32px", // Match control height
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: "0 8px", // Adjust spacing for alignment
        color: "#191919", // Optional: Customize the color of the clear icon
        cursor: "pointer", // Makes it clear that it's clickable
        display: "flex",
        alignItems: "center", // Center-align within the dropdown box
        justifyContent: "center",
        marginTop: '30%'
    }),
    menu: (base) => ({
        ...base,
        marginTop: "2px",
    }),
    option: (base) => ({
        ...base,
        padding: "8px", // Adjust padding for options
    }),
};


// Dropdown with a search bar
export const CustomDropdown = (props) => {
    return (
        <Select
            {...props}
            components={{
                DropdownIndicator: CustomDropdownIndicator,
                IndicatorSeparator: () => null,
            }}
            styles={customStyles}
        />
    );
};