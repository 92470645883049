import React, { useState } from "react";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
// import img1 from "../three.png";
import img1 from "../logonew.png";
import shape1 from "../../assets/images/review/shape.png";
import client1 from "../../assets/images/review/client.jpg";
import icon1 from "../../assets/images/review/briefcase.png";
import icon2 from "../../assets/images/review/network.png";
import icon3 from "../../assets/images/review/chart.png";

function Review(props) {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const onVWEnter = () => {
    setViewPortEntered(true);
  };

  const size = window.innerWidth <= 991 ? "80vw" : "40vw";

  return (
    <section className="inner-review-section">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-7 col-md-12 d-flex justify-content-center align-items-center">
            <div className="wd-review-job thumb2 widget-counter stc">
              <div className="thumb mt-5">
                <img src={img1} alt="images" style={{ width: size }} />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 wow fadeInRight">
            <div className="wd-review-job contentbox1 page-text stc">
              <h3>About Us</h3>
              <p style={{ fontSize: "1.2rem" }}>
                The PM Network Alliance is a community of technology
                professionals, including Product, Program, Project Managers, and
                other it professions who are dedicated to excellence in delivery
                and execution.
              </p>
              <ul
                style={{
                  marginTop: "20px",
                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                  fontSize: "20px",
                  lineHeight: "1.6",
                  color: "#64666C",
                }}
              >
                <li style={{ marginBottom: "10px" }}>
                  <strong>Our Misson :</strong> To foster networking, while
                  staying ahead of emerging trends and technologies that impact
                  delivery and execution in the tech sector.
                </li>
                <li>
                  <strong>What to Expect :</strong> Build connections with
                  industry peers, Insights from thought leaders and industry
                  experts, Practical knowledge to enhance product delivery,
                  Industry news and trends through curated content.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Review;
