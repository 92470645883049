const dataCate = [
  {
    id: 1,
    title: "Program Management Roles",
    unit: "120 Jobs available",
    value: 'Program Manager'

  },
  {
    id: 2,
    title: "Product Management Roles",
    unit: "120 Jobs available",
    value: 'Product Manager'
  },

  {
    id: 3,
    title: "Project Management Roles",
    unit: "120 Jobs available",
    value: 'Project Manager',
    margin: "remove",
  },
  {
    id: 4,
    title: "Project Coordination Roles",
    unit: "120 Jobs available",
    value: 'Project Coordinator'
  },
  {
    id: 5,
    title: "Other Related Delivery and Execution Roles",
    unit: "120 Jobs available",
    value: 'Other'
  },
];

export default dataCate;
