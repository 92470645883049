import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Gotop from "../Components/gotop";

import JobSec3 from "../Components/Job/JobSec3";

import MobileMenu from "../Components/MobileMenu";

Joblist_v3.propTypes = {};

function Joblist_v3() {

  const [isShowMobile, setShowMobile] = useState(false);

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  return (
    <>
      <MobileMenu />
      <Navbar clname="act1" handleMobile={handleMobile} />
      <JobSec3 />
      <div style={{ marginBottom: "85px" }}></div>
      <Footer />
      <Gotop />
    </>
  );
}

export default Joblist_v3;
